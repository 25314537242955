// Sass math
@use "sass:math";
@use "sass:map";
@use "variables" as *;
@use "material" as mat;
/* Import Bootstrap */
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/gridstack/dist/gridstack.min.css";
@import "../node_modules/gridstack/dist/gridstack-extra.min.css";
@import "../node_modules/@xyflow/react/dist/style.css";

// Default theme
:root {
    /* Color Definitions */
    /* Zixi */
    --zixi-purple: #{$zixi-purple};
    --zixi-red: #{$zixi-red};
    --zixi-green: #{$zixi-green};
    --zixi-blue: #{zixi-blue};

    /* Blues */
    --blue-50: #{$blue-50};
    --blue-100: #{$blue-100};
    --blue-200: #{$blue-200};
    --blue-300: #{$blue-300};
    --blue-400: #{$blue-400};
    --blue-500: #{$blue-500};
    --blue-600: #{$blue-600};
    --blue-700: #{$blue-700};
    --blue-800: #{$blue-800};
    --blue-900: #{$blue-900};

    /* Blue Grays */
    --blue-gray-50: #{$blue-gray-50};
    --blue-gray-100: #{$blue-gray-100};
    --blue-gray-200: #{$blue-gray-200};
    --blue-gray-300: #{$blue-gray-300};
    --blue-gray-400: #{$blue-gray-400};
    --blue-gray-500: #{$blue-gray-500};
    --blue-gray-600: #{$blue-gray-600};
    --blue-gray-700: #{$blue-gray-700};
    --blue-gray-800: #{$blue-gray-800};
    --blue-gray-900: #{$blue-gray-900};
    --blue-gray-1000: #{$blue-gray-1000};

    --red-50: #{$red-50};
    --green-50: #{$green-50};
    --orange-50: #{$orange-50};

    --red-100: #{$red-100};
    --green-100: #{$green-100};
    --orange-100: #{$orange-100};

    --red-200: #{$red-200};
    --green-200: #{$green-200};
    --orange-200: #{$orange-200};

    --red-800: #{$red-800};
    --green-800: #{$green-800};
    --orange-800: #{$orange-800};

    --red-900: #{$red-900};
    --green-900: #{$green-900};
    --orange-900: #{$orange-900};

    --cyan-50: #{$cyan-50};
    --cyan-100: #{$cyan-100};
    --cyan-200: #{$cyan-200};
    --cyan-800: #{$cyan-800};
    --cyan-900: #{$cyan-900};

    /* Status */
    --status-good: #{$status-good}; // Google Green 800
    --status-warning: #{$status-warning}; // Google Orange 800
    --status-error: #{$status-error}; // Google Red 700
    --status-none: #{$status-none}; // Google Gray 600
    --status-info: #{$status-info}; // Google Cyan 700

    /* Bootstrap Variable Overrides */
    /* Grays */
    --white: #{$white};
    --gray-50: #{$gray-50};
    --gray-100: #{$gray-100};
    --gray-200: #{$gray-200};
    --gray-300: #{$gray-300};
    --gray-400: #{$gray-400};
    --gray-500: #{$gray-500};
    --gray-600: #{$gray-600};
    --gray-700: #{$gray-700};
    --gray-800: #{$gray-800};
    --gray-900: #{$gray-900};
    --black: #{$black};

    --color-white: #{$white};
    --color-black: #{$black};
    --color-disabled: #{$color-disabled};

    --blue: #{$blue-700};
    --indigo: #{$indigo};
    --purple: #{$purple};
    --pink: #{$pink};
    --red: #{$status-error};
    --orange: #{$status-warning};
    --yellow: #{$yellow};
    --green: #{$status-good};
    --teal: #{$teal};
    --cyan: #{$status-info};
    --dark: #{$gray-900};

    --warning: #{$orange};
    --primary: #{$blue};
    --secondary: #{$blue-gray-800};

    --placeholder: #{$gray-300};
    --hover-bg-color: #{$gray-100};
    --active-bg-color: #{$blue-50};

    /* Body */
    --body-bg: #{$white};
    --body-color: #{$gray-900};
    --box-shadow-sm: #{$box-shadow-sm};

    // Mat
    --mat-table-row-item-label-text-tracking: 0;
    --mdc-checkbox-state-layer-size: 16px;
    --mat-standard-button-toggle-height: 34px;

    --mat-form-field-container-text-size: #{$font-size-base};
    --mat-form-field-container-text-line-height: 1.5;
    --mat-form-field-outlined-label-text-populated-size: #{$font-size-base};

    --mat-select-trigger-text-size: #{$font-size-base};
    --mat-select-trigger-text-tracking: 0;
    --mat-select-trigger-text-line-height: 1.5;
    --mat-select-panel-background-color: #{$white};
    --mat-select-enabled-trigger-text-color: #{$gray-900};
    --mat-select-disabled-trigger-text-color: rgba(33, 33, 33, 0.5);
    --mat-select-placeholder-text-color: #{$gray-300};
    --mat-select-enabled-arrow-color: #{$gray-900};
    --mat-select-disabled-arrow-color: rgba(33, 33, 33, 0.5);
    --mat-select-focused-arrow-color: #{$blue};
    --mat-select-invalid-arrow-color: #{$status-error};

    --mdc-outlined-text-field-label-text-size: #{$font-size-base};
    --mdc-outlined-text-field-label-text-tracking: 0;
    --mdc-outlined-text-field-label-text-color: #{$gray-400};
    --mdc-outlined-text-field-focus-label-text-color: #{$gray-700};
    --mdc-outlined-text-field-input-text-color: #{$gray-900};
    --mdc-outlined-text-field-outline-color: #{$gray-400};
    --mdc-outlined-text-field-hover-outline-color: #{$gray-500};
    --mdc-outlined-text-field-focus-outline-color: #{$gray-500};

    --mdc-checkbox-unselected-icon-color: #{$gray-400};
    --mdc-checkbox-unselected-focus-icon-color: #{$gray-500};
    --mdc-checkbox-unselected-hover-icon-color: #{$gray-500};
    --mdc-checkbox-unselected-pressed-icon-color: #{$gray-600};
    --mdc-checkbox-unselected-focus-state-layer-color: #{$white};
    --mdc-checkbox-unselected-hover-state-layer-color: #{$white};
    --mdc-checkbox-unselected-pressed-state-layer-color: #{$white};
    --mdc-checkbox-selected-checkmark-color: #{$white};
    --mdc-checkbox-selected-focus-icon-color: #{$blue-gray-800};
    --mdc-checkbox-selected-hover-icon-color: #{$blue-gray-800};
    --mdc-checkbox-selected-icon-color: #{$blue-gray-800};
    --mdc-checkbox-selected-pressed-icon-color: #{$blue-gray-800};
    --mdc-checkbox-selected-focus-state-layer-color: #{$blue-gray-800};
    --mdc-checkbox-selected-hover-state-layer-color: #{$blue-gray-800};
    --mdc-checkbox-selected-pressed-state-layer-color: #{$blue-gray-800};
    --mdc-checkbox-disabled-selected-checkmark-color: rgba(255, 255, 255, 0.5);
    --mdc-checkbox-disabled-selected-icon-color: rgba(55, 71, 79, 0.5);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(189, 189, 189, 0.5);

    --mat-paginator-enabled-icon-color: #{$gray-600};
    --mat-paginator-disabled-icon-color: #{$gray-600};

    --mat-standard-button-toggle-divider-color: #{$gray-400};
    --mat-standard-button-toggle-text-color: #{$blue};
    --mat-standard-button-toggle-background-color: #{$white};
    --mat-standard-button-toggle-state-layer-color: #{$blue};
    --mat-standard-button-toggle-selected-state-background-color: #{$blue};
    --mat-standard-button-toggle-selected-state-text-color: #{$white};
    --mat-standard-button-toggle-disabled-state-text-color: #{$blue};
    --mat-standard-button-toggle-disabled-state-background-color: #{$white};
    --mat-standard-button-toggle-disabled-selected-state-text-color: #{$white};
    --mat-standard-button-toggle-disabled-selected-state-background-color: #{$blue};

    --mat-menu-item-label-text-size: #{$font-size-base};
    --mat-menu-item-label-text-tracking: 0;
    --mat-menu-item-label-text-line-height: #{$font-size-base};

    --mat-menu-item-label-text-color: #{$gray-900};
    --mat-menu-item-icon-color: #{$gray-900};
    --mat-menu-container-color: #{$white};
    --mat-menu-item-hover-state-layer-color: #{$gray-100};
    --mat-menu-item-focus-state-layer-color: #{$gray-200};

    --mdc-typography-body2-line-height: 1.5;
    --mdc-typography-body2-letter-spacing: 0;

    --mat-option-label-text-line-height: 1.5;
    --mat-option-label-text-tracking: 0;
    --mat-option-label-text-size: $h6-font-size;
    --mat-option-selected-state-label-text-color: #{$blue};
    --mat-option-label-text-color: #{$gray-900};

    --mdc-typography-button-letter-spacing: 0;
    --mdc-text-button-label-text-color: #{$gray-900};
    --mdc-text-button-disabled-label-text-color: rgba(33, 33, 33, 0.5);

    --mat-form-field-container-text-tracking: 0;
    --mat-table-header-headline-tracking: 0;
}

// Dark theme
.dark {
    --blue-50: #{$blue-900};
    --blue-100: #{$blue-800};
    --blue-200: #{$blue-700};
    --blue-300: #{$blue-600};
    --blue-400: #{$blue-500};
    --blue-500: #{$blue-400};
    --blue-600: #{$blue-300};
    --blue-700: #{$blue-200};
    --blue-800: #{$blue-100};
    --blue-900: #{$blue-50};
    //
    --red-50: #{$dark-status-error-bg};
    --green-50: #{$dark-status-good-bg};
    --orange-50: #{$dark-status-warning-bg};

    --red-100: #{$red-800};
    --green-100: #{$green-800};
    --orange-100: #{$orange-800};

    --red-800: #{$red-100};
    --green-800: #{$green-100};
    --orange-800: #{$orange-100};

    --red-900: #{$red-100};
    --green-900: #{$green-100};
    --orange-900: #{$orange-100};

    --cyan-50: #{$dark-status-info-bg};
    --cyan-100: #{$cyan-800};
    --cyan-200: #{$cyan-700};
    --cyan-800: #{$cyan-100};
    --cyan-900: #{$cyan-100};
    //
    --status-good: #{$dark-status-good};
    --status-warning: #{$dark-status-warning};
    --status-error: #{$dark-status-error};
    --status-none: #{$dark-status-none};
    --status-info: #{$dark-status-info};
    --purple: #{$dark-purple};
    --color-disabled: #{$blue-gray-1000};
    //
    --color-white: #{$black};
    --color-black: #{$white};
    --white: #{$blue-gray-900};
    --gray-50: #{$blue-gray-950};
    //
    --gray-100: #{$blue-gray-1000};
    //
    --gray-200: #{$blue-gray-800};
    --gray-300: #{$blue-gray-700};
    --gray-400: #{$blue-gray-600};
    --gray-500: #{$blue-gray-500};
    --gray-600: #{$blue-gray-400};
    --gray-700: #{$blue-gray-300};
    --gray-800: #{$blue-gray-200};
    --gray-900: #{$blue-gray-100};
    --black: #{$white};
    //
    --primary: #{$dark-primary};
    --secondary: #{$dark-secondary};
    --placeholder: #{$blue-gray-600};
    --bg: #{$blue-gray-900};
    --hover-bg-color: #{$blue-gray-800};
    --active-bg-color: #{$dark-primary-bg};
    //
    /* Body */
    --body-bg: #{$blue-gray-900};
    --body-color: #{$blue-gray-50};
    --box-shadow-sm: #{$dark-box-shadow-sm};

    // Mat
    --mdc-outlined-text-field-label-text-color: #{$blue-gray-600};
    --mdc-outlined-text-field-input-text-color: #{$blue-gray-50};
    --mdc-outlined-text-field-focus-label-text-color: #{$blue-gray-300};
    --mdc-outlined-text-field-outline-color: #{$blue-gray-600};
    --mdc-outlined-text-field-hover-outline-color: #{$blue-gray-500};
    --mdc-outlined-text-field-focus-outline-color: #{$blue-gray-500};
    --mat-select-panel-background-color: #{$blue-gray-900};
    --mat-select-enabled-trigger-text-color: #{$blue-gray-50};
    --mat-select-disabled-trigger-text-color: rgba(207, 216, 220, 0.5);
    --mat-select-placeholder-text-color: #{$blue-gray-700};
    --mat-select-enabled-arrow-color: #{$blue-gray-50};
    --mat-select-disabled-arrow-color: rgba(207, 216, 220, 0.5);
    --mat-select-focused-arrow-color: #{$dark-primary};
    --mat-select-invalid-arrow-color: #{$dark-status-error};
    --mat-paginator-enabled-icon-color: #{$blue-gray-400};
    --mat-paginator-disabled-icon-color: #{$blue-gray-400};
    --mdc-checkbox-unselected-icon-color: #{$blue-gray-600};
    --mdc-checkbox-unselected-focus-icon-color: #{$blue-gray-500};
    --mdc-checkbox-unselected-hover-icon-color: #{$blue-gray-500};
    --mdc-checkbox-unselected-pressed-icon-color: #{$blue-gray-400};
    --mdc-checkbox-unselected-focus-state-layer-color: #{$black};
    --mdc-checkbox-unselected-hover-state-layer-color: #{$black};
    --mdc-checkbox-unselected-pressed-state-layer-color: #{$black};
    --mdc-checkbox-selected-checkmark-color: #{$black};
    --mdc-checkbox-selected-focus-icon-color: #{$dark-secondary};
    --mdc-checkbox-selected-hover-icon-color: #{$dark-secondary};
    --mdc-checkbox-selected-icon-color: #{$dark-secondary};
    --mdc-checkbox-selected-pressed-icon-color: #{$dark-secondary};
    --mdc-checkbox-selected-focus-state-layer-color: #{$dark-secondary};
    --mdc-checkbox-selected-hover-state-layer-color: #{$dark-secondary};
    --mdc-checkbox-selected-pressed-state-layer-color: #{$dark-secondary};
    --mdc-checkbox-disabled-selected-checkmark-color: rgba(0, 0, 0, 0.5);
    --mdc-checkbox-disabled-selected-icon-color: rgba(236, 239, 241, 0.5);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(84, 110, 122, 0.5);
    --mat-standard-button-toggle-divider-color: #{$blue-gray-600};
    --mat-standard-button-toggle-text-color: #{$dark-primary};
    --mat-standard-button-toggle-background-color: #{$blue-gray-900};
    --mat-standard-button-toggle-state-layer-color: #{$dark-primary};
    --mat-standard-button-toggle-selected-state-background-color: #{$dark-primary};
    --mat-standard-button-toggle-selected-state-text-color: #{$blue-gray-900};
    --mat-standard-button-toggle-disabled-state-text-color: #{$dark-primary};
    --mat-standard-button-toggle-disabled-state-background-color: #{$blue-gray-900};
    --mat-standard-button-toggle-disabled-selected-state-text-color: #{$blue-gray-900};
    --mat-standard-button-toggle-disabled-selected-state-background-color: #{$dark-primary};
    --mat-menu-item-label-text-color: #{$blue-gray-50};
    --mat-menu-item-icon-color: #{$blue-gray-50};
    --mat-menu-container-color: #{$blue-gray-900};
    --mat-menu-item-hover-state-layer-color: #{$blue-gray-800};
    --mat-menu-item-focus-state-layer-color: #{$blue-gray-700};

    --mat-option-selected-state-label-text-color: #{$dark-primary};
    --mat-option-label-text-color: #{$blue-gray-50};
}

/* Utility */
.clear-both {
    clear: both;
}

.hide {
    display: none !important;
    transition: none !important;
}

.dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;
    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
}

.prevent-click {
    opacity: 1;
    pointer-events: none;
}

// Flex
.flex-grow-3 {
    flex-grow: 3;
}

.flex-grow-2 {
    flex-grow: 2;
}

.flex-1 {
    flex: 1;
}

.flex-0 {
    flex: 0;
}

.flex-basis-0 {
    flex-basis: 0 !important;
}

.flex-basis-100 {
    flex-basis: 100% !important;
}

.flex-basis-50 {
    flex-basis: 50% !important;
}

.grid-gap-2 {
    grid-gap: 0.5rem;
}

.grid-gap-3 {
    grid-gap: 1rem;
}

.d-flex-imp {
    display: flex !important;
}

// Heights
.h-0 {
    height: 0 !important;
}

// Widths
@include media-breakpoint-down(md) {
    .w-25-sm {
        width: 25% !important;
    }
}

.w-25px {
    width: 25px !important;
    min-width: 25px !important;
}

.w-26px {
    width: 26px !important;
    min-width: 26px !important;
}

.w-28px {
    width: 28px !important;
    min-width: 28px !important;
}

th.w-28px fa-icon {
    cursor: pointer;
}

.w-30px {
    width: 30px !important;
    min-width: 30px !important;
}

.w-35px {
    width: 35px !important;
    min-width: 35px !important;
}

.w-40px {
    width: 40px !important;
    min-width: 40px !important;
}

.w-52px {
    width: 52px !important;
    min-width: 52px !important;
}

.w-50px {
    width: 50px !important;
    min-width: 50px !important;
}

.w-60px {
    width: 60px !important;
    min-width: 60px !important;
}

.w-70px {
    width: 70px !important;
    min-width: 70px !important;
}

.w-80px {
    width: 80px !important;
    min-width: 80px !important;
}

.w-90px {
    width: 90px !important;
    min-width: 90px !important;
}

.w-100px {
    width: 100px !important;
    min-width: 100px !important;
}

.w-110px {
    width: 110px !important;
    min-width: 110px !important;
}

.w-120px {
    width: 120px !important;
    min-width: 120px !important;
}

.w-130px {
    width: 130px !important;
    min-width: 130px !important;
}

.w-140px {
    width: 140px !important;
    min-width: 140px !important;
}

.w-150px {
    width: 150px !important;
    min-width: 150px !important;
}

.w-160px {
    width: 160px !important;
    min-width: 160px !important;
}

.w-180px {
    width: 180px !important;
    min-width: 180px !important;
}
.w-190px {
    width: 190px !important;
    min-width: 190px !important;
}

.w-200px {
    width: 200px !important;
    min-width: 200px !important;
}

.w-240px {
    width: 240px !important;
    min-width: 240px !important;
}

.w-300px {
    width: 300px !important;
    min-width: 300px !important;
}

// Max Widths
.maxw-60px {
    max-width: 60px !important;
}

.maxw-80px {
    max-width: 80px !important;
}

.maxw-100px {
    max-width: 100px !important;
}

.maxw-120px {
    max-width: 120px !important;
}

.maxw-140px {
    max-width: 140px !important;
}

.maxw-160px {
    max-width: 160px !important;
}

.maxw-200px {
    max-width: 200px !important;
}

.maxw-240px {
    max-width: 240px !important;
}

.maxw-400px {
    max-width: 400px !important;
}

.maxw-600px {
    max-width: 600px !important;
}

// Min Widths
.minw-100pc {
    min-width: 100% !important;
}

.minw-320px {
    min-width: 320px !important;
}

.minw-300px {
    min-width: 300px !important;
}

.minw-240px {
    min-width: 240px !important;
}

.minw-220px {
    min-width: 220px !important;
}

.minw-200px {
    min-width: 200px !important;
}

.minw-160px {
    min-width: 160px !important;
}

.minw-120px {
    min-width: 120px !important;
}

.minw-100px {
    min-width: 100px !important;
}

.minw-90px {
    min-width: 90px !important;
}

.minw-80px {
    min-width: 80px !important;
}

.minw-70px {
    min-width: 70px !important;
}

.minw-50px {
    min-width: 50px !important;
}

// Min Heights
.minh-20px {
    min-height: 20px !important;
}

.minh-52px {
    min-height: 52px !important;
}

// Other
.ms-1px {
    margin-left: 1px;
}

.ms-neg-1rem {
    margin-left: -$spacer !important;
}

.d-block-important {
    display: block !important;
}

.d-none-important {
    display: none !important;
}

.border-radius-sm {
    height: 31px;
}

.lh-10 {
    line-height: 1;
}

.lh-15 {
    line-height: 1.5;
}

.fs-1rem {
    font-size: 1rem;
}

.table-border-bottom {
    border-bottom: 1px solid var(--gray-300);
}

.table-border-top {
    border-top: 1px solid var(--gray-300);
}

.disabled {
    opacity: $btn-disabled-opacity;
    cursor: default !important;
}

.fake-disabled {
    opacity: $btn-disabled-opacity;
}

.very-disabled {
    opacity: math.div($btn-disabled-opacity, 4);
}

a.disabled {
    pointer-events: none;
}

.appear-disabled {
    cursor: not-allowed !important;
    opacity: $btn-disabled-opacity !important;
    background-color: transparent !important;
}

.appear-disabled:hover {
    cursor: not-allowed !important;
    opacity: $btn-disabled-opacity !important;
    background-color: transparent !important;
}

.btn-outline-primary.appear-disabled {
    color: var(--primary) !important;
}

table a.disabled {
    color: var(--status-none) !important;
}

table a.appear-disabled,
table a.appear-disabled:hover {
    color: var(--status-none) !important;
}

table .disabled-message {
    position: absolute;
    z-index: 1;
    margin-left: $spacer;
    background: var(--white);
}

.ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.ellipsis-label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-bottom: 0;
}

.whitespace-nowrap {
    white-space: nowrap;
}

.list-style-none {
    list-style-type: none;
    padding-left: math.div($spacer, 2);
}

.list-style-none li {
    margin-bottom: math.div($spacer, 2);
}

.list-style-none li:last-child {
    margin-bottom: 0;
}

.tag {
    position: relative;
    top: -2px;
}

.cursor-pointer {
    cursor: pointer;
}

.no-bg {
    background: transparent !important;
}

.no-top-border {
    border-top: none !important;
}

.top-0 {
    top: 0;
}

.top-1px {
    top: 1px;
}

.btm-25px {
    bottom: 25px !important;
}

.white {
    color: white;
}

.bg-gray-50 {
    background-color: var(--gray-50);
}

.semibold {
    font-weight: $font-weight-semibold !important;
}

.not-bold {
    font-weight: $font-weight-normal !important;
}

.bold {
    font-weight: $font-weight-bold !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.overflow-y-hidden {
    overflow-y: hidden !important;
}

.link-underline-primary {
    text-decoration: underline;
}

/* Components */
app-topbar {
    flex: 0 0 auto;
}

.timezone-top-bar {
    display: flex;
    align-items: center;

    ng-select {
        width: 280px;

        ng-dropdown-panel {
            min-width: 200px;
        }
    }
}

@include media-breakpoint-down(xl) {
}

@include media-breakpoint-down(lg) {
}

@include media-breakpoint-down(sm) {
}

@include media-breakpoint-down(xs) {
}

app-login,
app-shared-login {
    display: flex;
    width: 100%;

    .login-container {
        flex: 1 1 auto;
        overflow: auto;
        padding: $spacer;
        background-color: var(--blue-gray-1000);
        text-align: center;
    }
    .login-container p {
        color: $white;
    }
    .login-container .card-body {
        padding: $spacer * 2;
    }
}

app-remote-accesses,
app-channels,
app-sources,
app-targets,
app-zecs,
app-clusters,
app-automations,
app-reports,
app-announcements-configurations,
app-users,
app-groups,
app-roles,
app-transcoding-profiles,
app-pid-mappings,
app-events-management,
app-tags,
app-map,
app-incidents,
app-accounts,
app-io-servers,
app-not-found,
app-custom-image,
app-shared-source-details {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
}

.my-account-container {
    padding: $spacer;
    width: 100%;
}

.group-container,
.report-container,
.tag-container,
.maps-container,
.incident-container,
.grid-page,
.account-container,
.custom-image-container,
.io-servers-container,
.ami-container {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
}

.grid-page {
    flex-direction: column;
}

app-events,
app-scte,
app-grids,
app-grid-detail,
app-grid-group-detail,
app-account-management,
app-notifications,
app-ssh-keys,
app-api-keys,
app-aws-accounts,
app-azure-accounts,
app-linode-accounts,
app-gcp-accounts,
app-ssos,
app-my-account,
app-live-event-details {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
}

app-scte {
    app-zx-table-actions {
        a {
            color: unset;
        }
    }
}

app-ssh-keys {
    position: relative;
}

.grid-detail-container {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
}

app-grids,
app-ssos,
app-notifications,
app-api-keys,
app-ssh-keys,
app-aws-accounts,
app-azure-accounts,
app-linode-accounts,
app-gcp-accounts {
    .tab-content > .active {
        padding-top: 0;
    }
}

app-grids,
app-ssos,
app-notifications,
app-api-keys,
app-ssh-keys,
app-aws-accounts,
app-azure-accounts,
app-linode-accounts,
app-gcp-accounts {
    .list-panel {
        padding-top: $spacer !important;
    }
}
// Not Tab List
app-zec-list,
app-live-events-list,
app-networks-list,
app-remote-access-list,
app-source-list,
app-channel-list,
app-target-list,
app-cluster-list,
app-automation-list,
app-report-list,
app-announcements-configurations-list,
app-transcoding-profile-list,
app-pid-mapping-list,
app-alerting-profiles-list,
app-tag-list,
app-map-list,
app-incident-list,
app-user-list,
app-role-list,
app-group-list,
app-account-list,
app-io-server-list,
app-multi-viewer-list,
app-custom-image,
app-custom-image-list {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
}

app-clusters,
app-cluster-list {
    flex-basis: 0;
}

app-live-events-list .event-filters {
    padding: 0;
}

// Tab List
app-grid-list,
app-grid-group-list,
app-notification-config-list,
app-warning-config-list,
app-kafka-config-list,
app-api-key-list,
app-ssh-key-list,
app-aws-account-list,
app-azure-account-list,
app-linode-account-list,
app-gcp-account-list,
app-sso-list,
app-grid-visualization {
    display: flex;
    flex: 0 0 auto;
    overflow: auto;
    height: 100%;
}

app-map-list,
app-grid-list,
app-grid-group-list,
app-notification-config-list,
app-warning-config-list,
app-kafka-config-list,
app-api-key-list,
app-ssh-key-list,
app-aws-account-list,
app-azure-account-list,
app-linode-account-list,
app-gcp-account-list,
app-sso-list {
    .left-container {
        flex-basis: auto;
        overflow: hidden;
    }
}

app-incident-list .left-container {
    flex-basis: 0%;
    overflow: hidden;
}

app-draw,
app-custom-background,
app-map-form,
app-incident-form,
app-feeder-form,
app-receiver-form,
app-zec-item-form,
app-live-event-form,
app-remote-access-form,
app-source-form,
app-source-form-failover,
app-source-form-inter-cluster,
app-source-form-monitor,
app-source-form-transcoded,
app-source-form-zixi,
app-source-form-ndi,
app-source-form-to-mediaconnect,
app-source-form-elemental-link,
app-source-form-udp-rtp,
app-source-form-srt,
app-source-form-pid-mapping,
app-source-form-file,
app-source-form-rtmp,
app-source-form-hls-pull,
app-source-form-elemental-link,
app-source-form-rist,
app-soruce-form-cdi,
app-source-form-jpegxs,
app-source-form-demux,
app-source-form-multiview,
app-source-form-multiplex,
app-channel-form,
app-input-errors-indicator,
app-channel-adaptive-form,
app-channel-transcode-form,
app-channel-pass-through-form,
app-channel-failover-form,
app-channel-mediaconnect-flow-form,
app-channel-medialive-channel-form,
app-zec-form,
app-ssh-key-form,
app-target-form,
app-target-http-form,
app-target-medialive-http-form,
app-target-zixi-form,
app-target-rist-form,
app-target-srt-form,
app-target-ndi-form,
app-target-udp-rtp-form,
app-target-rtmp-form,
app-target-cdi-form,
app-target-jpegxs-form,
app-target-entitlement-form,
app-cluster-form,
app-broadcaster,
app-broadcasters,
app-broadcaster-form,
app-automation-form,
app-report-form,
app-aws-account-form,
app-azure-account-form,
app-linode-account-form,
app-gcp-account-form,
app-grid-form,
app-grid-group-form,
app-transcoding-profile-form,
app-pid-mapping-form,
app-alerting-profile-form,
app-tag-form,
app-task-form,
app-schedule-form,
app-user-form,
app-announcement-configurations-form,
app-role-form,
app-sso-form,
app-kafka-form,
app-api-key-form,
app-group-form,
app-io-server-form,
app-account-form,
app-custom-image-form,
app-multi-viewer-form {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
}

.page-container {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    width: 100%;
}

.page-container.expanded {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background: var(--gray-50);
    height: 100%;
    width: 100%;
}

app-map-form form,
app-incident-form form,
app-feeder-form form,
app-receiver-form form,
app-zec-item-form form,
app-live-event-form form,
app-announcement-configurations-form form,
app-remote-access-form form,
app-source-form form,
app-source-form-failover form,
app-source-form-inter-cluster form,
app-source-form-monitor form,
app-source-form-transcoded form,
app-source-form-zixi form,
app-source-form-ndi form,
app-source-form-to-mediaconnect form,
app-source-form-elemental-link form,
app-source-form-udp-rtp form,
app-source-form-srt form,
app-source-form-pid-mapping form,
app-source-form-file form,
app-source-form-rtmp form,
app-source-form-elemental-link form,
app-source-form-hls-pull form,
app-source-form-rist form,
app-source-form-demux form,
app-source-form-multiview form,
app-source-form-multiplex form,
app-ssh-key-form form,
app-channel-form form,
app-input-errors-indicator form,
app-channel-adaptive-form form,
app-channel-transcode-form form,
app-channel-pass-through-form form,
app-channel-failover-form form,
app-channel-mediaconnect-flow-form form,
app-channel-medialive-channel-form form,
app-zec-form form,
app-target-form form,
app-target-http-form form,
app-target-medialive-http-form form,
app-target-zixi-form form,
app-target-rist-form form,
app-target-srt-form form,
app-target-ndi-form form,
app-target-udp-rtp-form form,
app-target-rtmp-form form,
app-target-cdi-form form,
app-target-jpegxs-form form,
app-target-entitlement-form form,
app-cluster-form form,
app-broadcasters .broadcasters-container,
app-broadcaster-form form,
app-automation-form form,
app-report-form form,
app-aws-account-form form,
app-azure-account-form form,
app-linode-account-form form,
app-gcp-account-form form,
app-grid-form form,
app-grid-group-form form,
app-transcoding-profile-form form,
app-pid-mapping-form form,
app-alerting-profile-form form,
app-tag-form form,
app-task-form form,
app-schedule-form form,
app-user-form form,
app-role-form form,
app-sso-form form,
app-kafka-form form,
app-api-key-form form,
app-group-form form,
app-io-server-form form,
app-account-form form,
app-multi-viewer-form form,
app-custom-image-form form,
app-form-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    height: 100%;
}

.page-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    height: 100%;
    width: 100%;
}

app-aws-account,
app-azure-account,
app-linode-account,
app-gcp-account,
.right-container {
    display: flex;
    flex: 5 1 auto;
    overflow: auto;
}

app-feeder,
app-receiver,
app-zec,
app-network,
app-multi-viewer,
app-cluster,
app-broadcaster,
app-new-broadcaster,
app-channel,
app-incident,
app-target,
app-transcoding-profile,
app-pid-mapping,
app-alerting-profile,
app-automation,
app-report,
app-announcement-configurations,
app-remote-access,
app-user,
app-group,
app-role,
app-account,
app-source,
app-mc-source,
.insights-wrapper {
    display: flex;
    flex: 1 1 auto;
    overflow: hidden;
}

app-broadcaster,
app-cluster {
    position: relative;
}

app-forgot-password,
app-reset-password,
app-set-password {
    display: flex;
    flex: 1 1 auto;
}

app-navigation {
    display: flex;
    flex: 0 0 auto;
    background-color: $blue-gray-900;
    z-index: 110;
}

app-error {
    display: block;
}

zx-events,
zx-scte,
app-cluster-broadcasters,
app-object-incident-list,
app-channel-targets,
app-target-transcoding-profiles,
app-source-sources,
app-channel-bitrates,
app-automation-tasks,
app-automation-schedules,
app-zec-inputs,
app-zec-outputs,
app-broadcaster-active-objects,
app-network-objects-state,
app-zec-outputs,
app-report-history,
app-source-downstream-objects {
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
}

app-layout-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 105;
}

/* Headings */
h1 {
    font-family: $font-family-title;
    font-weight: $font-weight-bold;
}

h2,
h4,
h5,
h6 {
    font-weight: $font-weight-semibold;
}

h3 {
    font-family: $font-family-title;
    font-weight: $font-weight-bold;
}

h3.modal-title {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-semibold;
    text-transform: unset;
    pointer-events: none;
}

h5 {
    color: var(--gray-800);
}

h3,
h4,
h5,
h6 {
    margin-bottom: math.div($spacer, 2);
}

p {
    margin-bottom: math.div($spacer, 2);
}

.ngb-highlight {
    font-weight: $font-weight-semibold !important;
}

/* Layout */
html {
    position: fixed;
    width: 100%;
}

body {
    background: $blue-gray-1000;
    color: var(--body-color);
    line-height: normal; // Need this to fix mermaid
}

app-root {
    line-height: 1.5; // Need this to reset back to normal
}

.navbar {
    display: flex;
    padding: math.div($spacer, 4) $spacer;
    height: $navbar-height;

    a {
        color: $dark-primary;
        &:hover {
            color: $blue-200;
        }
    }

    zx-breadcrumbs {
        display: flex;
        flex: 1 1 0%;
        height: 35px;
        min-width: 0;
        border-left: 1px solid black !important;
        padding-left: $spacer;
        margin-left: 31px;
    }

    .btn-link {
        color: $white !important;
        text-decoration: none;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

.navbar-brand {
    padding: 0;
    font-size: 1.25rem;
    height: 35px;
    margin-right: math.div($spacer, 2);
}

@include media-breakpoint-down(xs) {
    .navbar-brand img {
        width: 106px;
    }
}

main.flex-container {
    display: flex;
    flex-direction: column;
    height: 100%; // calc and set based on nav height
    width: 100%;
    background: var(--gray-50);
}

.content-container {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    min-height: calc(100vh - 48px);
    overflow: auto;
    background: var(--gray-50);
}

content {
    flex-direction: column;
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    background: var(--gray-50);
    width: 100%;
}

app-critical-announcements > div {
    padding: 0em;
    display: flex;
    flex-direction: column;
    gap: math.div($spacer, 2);
    height: fit-content;
    border-bottom: 1px solid var(--gray-300);

    ngb-alert.announcement {
        margin-bottom: 0;
        border-radius: 0em;
    }
    .alert-dismissible {
        .close {
            padding: math.div($spacer, 2) $spacer;
        }
    }
}

.outlet-container {
    display: flex;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}

@include media-breakpoint-down(lg) {
    .content-container {
        flex-direction: column;
    }
}

ng-component,
app-broadcasters-new {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    width: 100%;
}

.content-area {
    flex: 1 1 auto;
    overflow: auto;
    padding: $spacer 0;
    background: var(--gray-50);
}

.row {
    --bs-gutter-x: 1rem;
}

/* Status */
.status-good,
.status-ok {
    color: var(--status-good);
}

.status-warning {
    color: var(--status-warning);
}

.status-error,
.status-bad {
    color: var(--status-error);
}

.status-info {
    color: var(--status-info);
}

.status-disabled,
.status-pending,
.status-none,
.status-no {
    color: var(--status-none);
}

.status-error-bg {
    background-color: var(--red-50) !important;
}

.status-warning-bg {
    background-color: var(--orange-50) !important;
}

.status-good-bg {
    background-color: var(--green-50) !important;
}

.dark {
    .status-error-bg {
        background-color: $dark-status-error-bg-table !important;
    }

    .status-warning-bg {
        background-color: $dark-status-warning-bg-table !important;
    }

    .status-good-bg {
        background-color: $dark-status-good-bg-table !important;
    }
}

/* Navbar */
.bg-dark {
    background-color: $blue-gray-950 !important;
    border-bottom: 1px solid $black;

    .btn-secondary {
        background-color: var(--zixi-green);
        color: $body-color;

        &:hover {
            color: $body-color;
            background-color: shade-color($zixi-green, 7.5%);
            border-color: shade-color($zixi-green, 10%);
        }
    }

    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
        color: $body-color;
        background-color: var(--zixi-green);
        border-color: var(--zixi-green);
    }
}

.bg-dark.impersonating {
    background-color: mix($blue-gray-900, $status-error, 70%) !important;

    &:hover {
        background-color: mix($blue-gray-900, $status-error, 90%) !important;
    }
}

/* Buttons */
button:focus {
    outline: 1px dotted;
}

.btn {
    --bs-btn-border-radius: #{$border-radius};
}

.btn-sm {
    font-size: $font-size-base;
}

.btn-xs {
    font-size: $h6-font-size;
}

.btn-play {
    border-radius: 13px;
    padding: math.div($spacer, 8) math.div($spacer, 2);
    background-color: var(--primary);
    color: var(--white) !important;
}

.btn-round {
    border-radius: 1.25rem;
    color: var(--gray-700);

    &:hover {
        border-color: var(--secondary);
    }
}

ngx-json-viewer {
    --ngx-json-key: var(--primary);
    --ngx-json-number: var(--status-info);
    --ngx-json-null-bg: var(--status-error);
    --ngx-json-string: var(--status-error);
    --ngx-json-object: var(--gray-700);
    --ngx-json-array: var(--gray-700);
    --ngx-json-boolean: var(--purple);
}

.btn-round-sm {
    border-radius: 14px;
    color: var(--secondary);

    &:hover {
        color: var(--white);
        border-color: var(--secondary);
        background-color: var(--secondary);
    }
}

.btn-round-xs {
    border-radius: $btn-border-radius;
    padding-left: math.div($spacer, 3);
    padding-right: math.div($spacer, 3);
    border-color: transparent;

    &:disabled {
        border-color: transparent;
    }
}

.btn-link {
    background-color: transparent;
    color: var(--primary);

    &:hover {
        color: var(--blue-800);
    }
}

.btn-copy {
    background-color: transparent;
    color: var(--primary);
    padding: math.div($spacer, 8) math.div($spacer, 2) !important;

    &:hover {
        color: var(--blue-800);
    }

    &.active,
    &:active {
        color: var(--blue-900) !important;
        border-color: transparent !important;
    }
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-nav-toggle {
    margin-right: math.div($spacer, 2);
    margin-left: -$spacer;
    border-right: 1px solid var(--blue-gray-700) !important;
    border-radius: 0;

    .fa-lg {
        font-size: 1.4rem;
    }
}

.fa-rotate-45 {
    transform: rotate(45deg);
}

.dropdown-item:hover {
    color: var(--body-color);
    background-color: var(--hover-bg-color);
}

.text-muted {
    color: var(--gray-600) !important;
}

.text-primary {
    color: var(--primary) !important;
    &:hover {
        color: var(--blue-800) !important;
    }

    &:disabled,
    &.disabled {
        opacity: $btn-disabled-opacity;
    }

    &.dropdown-item {
        &.active,
        &:active {
            color: var(--body-bg) !important;
            background-color: var(--primary) !important;
        }
    }
}

.text-secondary {
    color: var(--body-color) !important;

    &:disabled,
    &.disabled {
        opacity: $btn-disabled-opacity;
    }

    &.dropdown-item {
        &.active,
        &:active {
            color: var(--body-bg) !important;
            background-color: var(--secondary) !important;
        }
    }
}

.text-warning {
    color: var(--status-warning) !important;
    &:hover {
        color: var(--orange-900) !important;
    }

    &:disabled,
    &.disabled {
        opacity: $btn-disabled-opacity;
    }

    &.dropdown-item {
        &.active,
        &:active {
            color: var(--body-bg) !important;
            background-color: var(--status-warning) !important;
        }
    }
}

.text-success {
    color: var(--status-good) !important;
    &:hover {
        color: var(--green-900) !important;
    }

    &:disabled,
    &.disabled {
        opacity: $btn-disabled-opacity;
    }

    &.dropdown-item {
        &.active,
        &:active {
            color: var(--body-bg) !important;
            background-color: var(--status-good) !important;
        }
    }
}

.text-danger {
    color: var(--status-error) !important;
    &:hover {
        color: var(--red-800) !important;
    }

    &:disabled,
    &.disabled {
        opacity: $btn-disabled-opacity;
    }

    &.dropdown-item {
        &.active,
        &:active {
            color: var(--body-bg) !important;
            background-color: var(--status-error) !important;
        }
    }
}

.btn-light {
    color: var(--body-color);
}

.bg-primary {
    background-color: var(--primary) !important;
}

.text-white {
    color: var(--white) !important;
}

.btn-primary {
    --bs-btn-color: var(--color-white);
    --bs-btn-bg: var(--primary);
    --bs-btn-border-color: var(--primary);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--blue-800);
    --bs-btn-hover-border-color: var(--blue-800);
    --bs-btn-active-color: var(--color-white);
    --bs-btn-active-bg: var(--blue-800);
    --bs-btn-active-border-color: var(--blue-800);
    --bs-btn-disabled-color: var(--color-white);
    --bs-btn-disabled-bg: var(--primary);
    --bs-btn-disabled-border-color: var(--primary);
}

.btn-secondary {
    --bs-btn-color: var(--color-white);
    --bs-btn-bg: var(--secondary);
    --bs-btn-border-color: var(--secondary);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--blue-gray-900);
    --bs-btn-hover-border-color: var(--blue-gray-900);
    --bs-btn-active-color: var(--color-white);
    --bs-btn-active-bg: var(--blue-gray-900);
    --bs-btn-active-border-color: var(--blue-gray-900);
    --bs-btn-disabled-color: var(--color-white);
    --bs-btn-disabled-bg: var(--secondary);
    --bs-btn-disabled-border-color: var(--secondary);
}

.dark {
    .btn-secondary {
        --bs-btn-hover-bg: #eceff1; //blue-gray-50;
        --bs-btn-hover-border-color: #eceff1;
        --bs-btn-active-bg: #eceff1;
        --bs-btn-active-border-color: #eceff1;
    }
}

.btn-danger {
    --bs-btn-color: var(--color-white);
    --bs-btn-bg: var(--status-error);
    --bs-btn-border-color: var(--status-error);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--red-800);
    --bs-btn-hover-border-color: var(--red-800);
    --bs-btn-active-color: var(--color-white);
    --bs-btn-active-bg: var(--red-800);
    --bs-btn-active-border-color: var(--red-800);
    --bs-btn-disabled-color: var(--color-white);
    --bs-btn-disabled-bg: var(--status-error);
    --bs-btn-disabled-border-color: var(--status-error);
}

.btn-warning {
    --bs-btn-color: var(--color-white);
    --bs-btn-bg: var(--status-warning);
    --bs-btn-border-color: var(--status-warning);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--orange-900);
    --bs-btn-hover-border-color: var(--orange-900);
    --bs-btn-active-color: var(--color-white);
    --bs-btn-active-bg: var(--orange-900);
    --bs-btn-active-border-color: var(--orange-900);
    --bs-btn-disabled-color: var(--color-white);
    --bs-btn-disabled-bg: var(--status-warning);
    --bs-btn-disabled-border-color: var(--status-warning);
}

.btn-success {
    --bs-btn-color: var(--color-white);
    --bs-btn-bg: var(--status-good);
    --bs-btn-border-color: var(--status-good);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--green-900);
    --bs-btn-hover-border-color: var(--green-900);
    --bs-btn-active-color: var(--color-white);
    --bs-btn-active-bg: var(--green-900);
    --bs-btn-active-border-color: var(--green-900);
    --bs-btn-disabled-color: var(--color-white);
    --bs-btn-disabled-bg: var(--status-good);
    --bs-btn-disabled-border-color: var(--status-good);
}

.btn-info {
    --bs-btn-color: var(--color-white);
    --bs-btn-bg: var(--status-info);
    --bs-btn-border-color: var(--status-info);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--cyan-800);
    --bs-btn-hover-border-color: var(--cyan-800);
    --bs-btn-active-color: var(--color-white);
    --bs-btn-active-bg: var(--cyan-800);
    --bs-btn-active-border-color: var(--cyan-800);
    --bs-btn-disabled-color: var(--color-white);
    --bs-btn-disabled-bg: var(--status-info);
    --bs-btn-disabled-border-color: var(--status-info);
}

.btn-outline-navbar {
    color: $dark-primary !important;
    border-color: transparent;
    background-color: transparent;

    &.green {
        color: var(--zixi-green) !important;
        border-color: var(--zixi-green);
        background-color: transparent;

        &:hover {
            color: var(--black) !important;
            background-color: var(--zixi-green);
            border-color: var(--zixi-green);
        }
    }

    &:hover {
        color: $black !important;
        background-color: $blue-200;
        border-color: $blue-200;
    }
}

.btn-outline-primary {
    --bs-btn-bg: var(--white);
    --bs-btn-color: var(--primary);
    --bs-btn-border-color: var(--gray-400);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--blue-800);
    --bs-btn-hover-border-color: var(--blue-800);
    --bs-btn-active-color: var(--color-white);
    --bs-btn-active-bg: var(--blue-800);
    --bs-btn-active-border-color: var(--blue-800);
    --bs-btn-disabled-color: var(--primary);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--gray-400);
}

.btn-outline-secondary {
    --bs-btn-bg: var(--white);
    --bs-btn-color: var(--secondary);
    --bs-btn-border-color: var(--gray-400);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--secondary);
    --bs-btn-hover-border-color: var(--blue-gray-900);
    --bs-btn-active-color: var(--color-white);
    --bs-btn-active-bg: var(--secondary);
    --bs-btn-active-border-color: var(--blue-gray-900);
    --bs-btn-disabled-color: var(---secondary);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--gray-400);

    &:focus {
        background-color: var(--gray-200);
        color: var(---secondary);
    }

    &:hover,
    &.active,
    &:active {
        background-color: var(--secondary) !important;
        color: var(--color-white) !important;
    }
}

.dark {
    .btn-outline-secondary {
        --bs-btn-active-color: #000;
        // TODO: find out why var and $ variables don't work here
        --bs-btn-active-bg: #eceff1; // $blue-gray-50
        // --bs-btn-active-border-color: $blue-gray-900;
    }
}

.btn-outline-warning {
    --bs-btn-bg: var(--white);
    --bs-btn-color: var(--status-warning);
    --bs-btn-border-color: var(--gray-400);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--orange-900);
    --bs-btn-hover-border-color: var(--orange-900);
    --bs-btn-active-color: var(--status-warning);
    --bs-btn-active-bg: var(--orange-50);
    --bs-btn-active-border-color: var(--orange-900);
    --bs-btn-disabled-color: var(--status-warning);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--gray-400);
}

.dark {
    .btn-outline-warning {
        --bs-btn-active-color: $white;
    }
}

.btn-outline-danger {
    --bs-btn-bg: var(--white);
    --bs-btn-color: var(--status-error);
    --bs-btn-border-color: var(--gray-400);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--red-800);
    --bs-btn-hover-border-color: var(--red-800);
    --bs-btn-active-color: var(--status-error);
    --bs-btn-active-bg: var(--red-50);
    --bs-btn-active-border-color: var(--red-800);
    --bs-btn-disabled-color: var(--status-error);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--gray-400);

    &:disabled {
        cursor: not-allowed !important;
    }
}

.dark {
    .btn-outline-danger {
        --bs-btn-active-color: $white;
    }
}

.btn-outline-info {
    --bs-btn-bg: var(--white);
    --bs-btn-color: var(--status-info);
    --bs-btn-border-color: var(--gray-400);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--cyan-800);
    --bs-btn-hover-border-color: var(--cyan-800);
    --bs-btn-active-color: var(--status-info);
    --bs-btn-active-bg: var(--cyan-50);
    --bs-btn-active-border-color: var(--cyan-800);
    --bs-btn-disabled-color: var(--status-info);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--gray-400);
}

.dark {
    .btn-outline-info {
        --bs-btn-active-color: $white;
    }
}

.btn-outline-success {
    --bs-btn-bg: var(--white);
    --bs-btn-color: var(--status-good);
    --bs-btn-border-color: var(--gray-400);
    --bs-btn-hover-color: var(--color-white);
    --bs-btn-hover-bg: var(--green-900);
    --bs-btn-hover-border-color: var(--green-900);
    --bs-btn-active-color: var(--status-good);
    --bs-btn-active-bg: var(--green-50);
    --bs-btn-active-border-color: var(--green-900);
    --bs-btn-disabled-color: var(--status-good);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--gray-400);
}

.dark {
    .btn-outline-success {
        --bs-btn-active-color: $white;
    }
}

a {
    color: var(--primary);
    text-decoration: none;
    &:hover {
        color: var(--blue-800);
    }
}

.logout-button {
    margin-right: -(math.div($spacer, 2));
}

.btn-outline-light {
    color: var(--white);
    background-color: transparent;
}

.btn-outline-green {
    color: var(--zixi-green);
    border-color: var(--zixi-green);
    background-color: var(--white);
    &:hover {
        color: var(--white);
        background-color: var(--zixi-green);
        border-color: var(--zixi-green);
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: none;
    }
}

.btn-outline-green:not(:disabled):not(.disabled):active,
.btn-outline-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-green.dropdown-toggle {
    color: var(--white);
    background-color: var(--zixi-green);
    border-color: var(--zixi-green);
}

.input-group {
    .btn {
        border-radius: $border-radius;
    }

    .btn-outline-light {
        border-color: var(--gray-400);
        border-left-color: var(--white);
        color: var(--body-color);
        background-color: var(--white);
    }

    .input-group-prepend,
    .input-label-append {
        label {
            margin: 0;
        }
    }
}

.input-group input:disabled ~ .btn-outline-light {
    background-color: var(--gray-50);
    border-left-color: transparent;
}

.input-group .btn-outline-light:not(:disabled):not(.disabled):active,
.input-group .btn-outline-light:not(:disabled):not(.disabled).active,
.input-group .show > .btn-outline-light.dropdown-toggle {
    color: var(--body-color);
    background-color: var(--white);
    border-color: var(--gray-400);
    border-left-color: var(--white);
}

.deselect-rows-btn {
    display: block;
    text-align: center;
    cursor: pointer;
    border: 1px dotted var(--body-color);
    height: 20px;
    line-height: 19px;
}

.btn-group-sm > .btn {
    --bs-btn-border-radius: #{$border-radius};
}

.btn-group > .btn,
.btn-group-vertical > .btn {
    cursor: pointer;
}

.btn-xs,
.btn-group-xs > .btn {
    line-height: 1;
}

.btn.condensed {
    padding-left: math.div($spacer, 2);
    padding-right: math.div($spacer, 2);
}

.btn-group.condensed .btn {
    padding-left: math.div($spacer, 2);
    padding-right: math.div($spacer, 2);
}

.btn-outline-primary.disabled.active {
    color: var(--color-white);
    background-color: var(--primary);
}

.tourStartButton {
    cursor: pointer;
    color: var(--gray-600);
}

// old 4.6 bootstrap style
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-primary {
    color: var(--color-white);
    background-color: var(--primary);
}

.badge-secondary {
    color: var(--color-white);
    background-color: var(--secondary);
}

.badge-success {
    color: var(--color-white);
    background-color: var(--status-good);
}

.badge-danger {
    color: var(--color-white);
    background-color: var(--status-error);
}

.badge-warning {
    color: var(--color-white);
    background-color: var(--status-warning);
}

.badge-light {
    color: var(--color-white);
    background-color: var(--status-none);
}

.dropdown-toggle-split {
    border-top-right-radius: $border-radius !important;
    border-bottom-right-radius: $border-radius !important;
}

.dropdown-menu {
    background-color: var(--white);
    border: 1px solid var(--gray-300);
    color: var(--body-color);
}

.shadow-sm {
    box-shadow: var(--box-shadow-sm);
}

.copy-button {
    position: absolute;
    right: $spacer;
    top: $spacer;
}

.alert-buttons {
    flex: 0 0 auto;
    margin-top: -1.75px;
    height: 24px;

    .btn {
        padding: math.div($spacer, 8) math.div($spacer, 4);
    }
}

/* Alerts */
.alert {
    word-break: break-word;
    padding: math.div($spacer, 2) $spacer;
    line-height: 21px;
}

.border {
    --bs-border-color: var(--gray-300);
}

/* Table */
.table {
    --bs-table-hover-bg: var(--hover-bg-color);
    --bs-table-hover-color: var(--body-color);
    background: var(--white);
    border-top: 1px solid var(--gray-300);
    color: var(--body-color);

    thead {
        tr {
            background-color: var(--gray-100);
        }
    }

    th {
        font-weight: $font-weight-semibold;
        font-size: $h6-font-size;
        color: var(--gray-800);
        border-color: var(--gray-300);
    }

    td {
        border-top-width: 0.5px;
        border-color: var(--gray-200);
    }

    &.bordered {
        border-left: 1px solid var(--gray-300);
        border-right: 1px solid var(--gray-300);
        border-bottom: 1px solid var(--gray-300);
        border-top: 1px solid var(--gray-300);

        &.is-invalid {
            border: 1px solid var(--status-error);

            th {
                border-top: 1px solid var(--status-error) !important;
            }
        }
    }

    tr {
        td {
            &.ellipsis {
                &.link-only {
                    a {
                        display: block;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }

            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .thead-light th,
    .thead-light td {
        color: var(--gray-700);
        background-color: var(--color-disabled);
    }
}

.table-borderless {
    border: none;
    tbody {
        tr {
            td {
                border: none;
                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }
    thead {
        tr {
            background-color: transparent;
            th {
                border: none;
                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }
    // stylelint-disable-next-line selector-max-universal
    > :not(caption) > * > * {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.table-fixed {
    table-layout: fixed;
}

.comma-list {
    span:after {
        content: ", ";
        white-space: pre;
    }

    span:last-child:after {
        content: none;
    }
}

.table-dark {
    color: var(--body-color);
}

.table-hover tbody tr:hover,
.row-hover:hover {
    background-color: var(--hover-bg-color);
}

.table-responsive {
    .table thead tr,
    .table tbody tr {
        white-space: nowrap;
    }
}

/* Table - Small */
.card-body .table-sm th:first-child,
.card-body .table-sm td:first-child,
.accordion-body .table-sm th:first-child,
.accordion-body .table-sm td:first-child {
    padding-left: 0.75rem;
}

.card-body .table-sm th:last-child,
.card-body .table-sm td:last-child,
.accordion-body .table-sm th:last-child,
.accordion-body .table-sm td:last-child {
    padding-right: 0.75rem;
}

/* Table - Selectable */
table.table-select tbody tr {
    cursor: pointer;
}

table.table-select tbody tr.selected {
    background-color: var(--active-bg-color);
}

table tbody tr.pinned {
    background-color: var(--gray-200);
}

/* Table - Sortable */
th[sortable] {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
}

th[sortable].desc:after,
th[sortable].asc:after {
    content: "";
    display: inline-block;
    position: relative;
    left: 0.25rem;
    top: 0.2rem;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='currentColor' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z' class=''%3e%3c/path%3e%3c/svg%3e");
    background-repeat: no-repeat;
    width: 1rem;
    height: 1rem;
}

th[sortable].desc:after {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='currentColor' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z' class=''%3e%3c/path%3e%3c/svg%3e");
}

/* Table - Nested */
.btn-chevron {
    text-align: center;
    color: var(--black);
    width: 28px;
    height: 30.4px;
    border-radius: 0;
    border: 0;
    // border-right: 1px solid var(--gray-300);
    padding: math.div($spacer, 3) 0.4rem;
    background-color: transparent !important;
    line-height: 1;

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: none;
    }

    &:hover,
    &.hover {
        color: var(--black);
    }
}

.table-nested {
    flex: 1;
    border-top: none;
    font-size: $h6-font-size;
    background-color: var(--white);
    table-layout: fixed;

    thead {
        tr {
            &:hover {
                cursor: default !important;
            }

            th {
                border-bottom-width: 1px;
                border-top-width: 0;
            }
        }
    }

    tbody {
        tr {
            td {
                border-top: 1px solid var(--gray-200);
            }
        }

        tr.selected {
            background-color: var(--active-bg-color);
        }
    }
}

/* Form */
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--placeholder) !important;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--placeholder) !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--placeholder) !important;
}

label {
    font-weight: $font-weight-semibold;
    margin-bottom: math.div($spacer, 4);
    color: var(--gray-800);
}

label fa-icon[icon="asterisk"],
legend fa-icon[icon="asterisk"] {
    position: relative;
    top: -0.25rem;
    margin-left: 0.25rem;
    color: var(--status-error);
}

label fa-icon[icon="asterisk"] svg,
legend fa-icon[icon="asterisk"] svg {
    font-size: 0.5rem;
}

label fa-icon[icon="info-circle"],
label fa-icon[icon="eye"],
label fa-icon[icon="eye-slash"],
label fa-icon[icon="lock"],
label fa-icon[icon="redo"],
legend fa-icon[icon="info-circle"],
legend fa-icon[icon="eye"],
legend fa-icon[icon="eye-slash"],
legend fa-icon[icon="lock"],
legend fa-icon[icon="redo"],
h3.section-title fa-icon[icon="redo"] {
    margin-left: math.div($spacer, 4);
}

h3.section-title fa-icon[icon="redo"] {
    position: relative;
    top: -(math.div($spacer, 8));
}

label fa-icon[icon="lock"],
legend fa-icon[icon="lock"] {
    position: relative;
    top: -1px;
}

label fa-icon[icon="eye"],
label fa-icon[icon="eye-slash"] {
    color: var(--primary);
}

.form-check {
    margin-bottom: 0;

    .form-check-input {
        width: $spacer;
        height: $spacer;
        border: 1px solid var(--gray-400);
        background-color: var(--white);

        &.secondary {
            &:checked {
                background-color: var(--secondary);
                border-color: var(--secondary);
            }
        }

        &:checked {
            background-color: var(--primary);
            border-color: var(--primary);
        }
    }

    .form-check-label {
        line-height: 1.7;
        font-weight: $font-weight-normal;
        color: var(--body-color);
        margin-bottom: 0;
    }
}

.form-switch {
    cursor: pointer;

    .form-check-input {
        cursor: pointer;
        margin-bottom: 2px;
        height: $spacer;
        background-color: var(--white);
        border-color: var(--gray-400);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23bdbdbd'/%3e%3c/svg%3e");

        &:active {
            filter: none;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
        }

        &:checked {
            background-color: var(--secondary);
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
        }

        &:focus {
            box-shadow: none;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23bdbdbd'/%3e%3c/svg%3e");
        }
    }

    .form-check-label {
        cursor: pointer;
        margin-left: 3px;
    }
}

.dark {
    .form-switch {
        cursor: pointer;

        .form-check-input {
            &:checked {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2337474f'/%3e%3c/svg%3e");
            }
        }
    }

    .form-check {
        .form-check-input:checked[type="checkbox"] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23263238' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
            background-color: var(--primary);
            border-color: var(--primary);
        }

        .form-check-input:checked[type="radio"] {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23263238'/%3e%3c/svg%3e");
        }
    }
}

.form-control,
.form-control:focus {
    color: var(--body-color);
    background-color: var(--white);
    border-color: var(--gray-400);
}

.form-control:disabled,
.form-control[readonly] {
    background-color: var(--gray-50);
    border-color: var(--gray-300);
}

.form-control[readonly].disabled {
    background-color: var(--gray-50) !important;
    border-color: var(--gray-300) !important;
}

.form-control-plaintext {
    color: var(--body-color);
    padding: 0;
    border: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:focus {
        outline: none;
    }
}

.form-control-sm {
    font-size: $font-size-base;
}

.form-control-xs {
    font-size: $font-size-base;
    min-height: auto;
    line-height: 1;
    padding-top: math.div($spacer, 8);
    padding-bottom: math.div($spacer, 8);
}

.custom-select {
    background-color: var(--white);
    color: var(--body-color);
    border-color: var(--gray-400);
}

legend {
    float: none;
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: $spacer;
}

fieldset {
    legend {
        display: inline-block;
        margin-bottom: math.div($spacer, 4);
        font-size: $font-size-base;
        font-weight: $font-weight-semibold;
        color: var(--gray-800);
    }

    &.bordered {
        border: 1px solid var(--gray-400);
        padding: math.div($spacer, 3.5) $spacer math.div($spacer, 2) $spacer;
        clear: both;
        border-radius: $border-radius;

        &.collapsed {
            border-left: none;
            border-bottom: none;
            border-right: none;
            padding-bottom: 0;
            border-radius: 0;
        }

        &.is-invalid,
        &.ng-invalid {
            border-color: var(--status-error);
        }

        &.ng-invalid {
            legend {
                color: var(--status-error);
            }
        }
    }
}

fieldset.bordered > legend,
zx-mediaconnect-vpc > legend,
zx-mediaconnect-mediastream > legend {
    margin-left: -17px;
    margin-bottom: 0;
    width: auto;
    padding-right: math.div($spacer, 2);
}

fieldset.bordered.is-invalid > legend {
    color: var(--status-error);
}

zx-mediaconnect-vpc > legend,
zx-mediaconnect-mediastream > legend {
    background: $gray-50;
    margin-top: -15px;
    float: left;
}

zx-mediaconnect-vpc > div,
zx-mediaconnect-mediastream > div {
    display: block;
    clear: both;
    padding-top: math.div($spacer, 4);
}

zx-mediaconnect-vpc legend fa-icon,
zx-mediaconnect-mediastream legend fa-icon {
    cursor: pointer;
}

.form-section-title {
    width: 100%;
    overflow: hidden;

    h3 {
        &.section-title {
            position: relative;
            z-index: 1;
            display: block;
            float: left;
            width: auto;
            padding-right: math.div($spacer, 2);
            color: var(--gray-600);

            &:after {
                // background: var(--body-color);
                background: var(--gray-600);
                content: "";
                height: 1px;
                position: absolute;
                left: 100%;
                top: 50%;
                width: 9999%;
            }

            &.status-error:after {
                background: var(--status-error);
            }
        }
    }
}

.toggle-fieldset-title.closed {
    width: 100%;
    position: relative;
    overflow: hidden;
}

h3.toggle-fieldset {
    position: relative;
    z-index: 1;
    display: block;
    float: left;
    background: var(--gray-50);
    width: auto;
    margin-bottom: -(math.div($spacer, 1.33));
    padding-right: math.div($spacer, 2);
    cursor: pointer;
    color: var(--primary);

    &.closed {
        //width: 100%;
        margin-bottom: $spacer;

        &:after {
            background: var(--primary);
            content: "";
            height: 1px;
            position: absolute;
            left: 100%;
            top: 50%;
            width: 9999%;
        }

        &.is-invalid:after {
            background: var(--status-error);
        }
    }

    .is-invalid {
        color: var(--status-error);
    }

    fa-icon {
        margin-left: math.div($spacer, 4);

        .svg-inline--fa {
            vertical-align: 0;
        }
    }
}

.toggle-fieldset-title ~ fieldset.bordered {
    padding-top: $spacer;
    padding-bottom: 0;
}

fieldset.bordered .row .col-12.col-xs-6 label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-bottom: 0;
}

fieldset.bordered .row .col-12.col-sm-6 label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-bottom: 0;
}

.error-note {
    display: block;
    color: var(--status-error);
}

.is-invalid ~ .invalid-feedback {
    display: block;
    color: var(--status-error);
}

fieldset.is-invalid .invalid-feedback {
    display: block;
}

.text-invalidation {
    display: block;
    color: #d32f2f;
    color: var(--status-error);
    font-size: 90%;
    margin-top: 3px;
}

.is-invalid .custom-control-input:valid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label,
.is-invalid .form-check-input:valid ~ .form-check-label,
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
    color: var(--status-error);
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none;
    padding-right: 0.75rem;
    border-color: var(--status-error);
}

.input-group.is-invalid .btn-outline-primary {
    color: var(--status-error);
    border-color: var(--status-error);
}

.valid-pair {
    width: 100%;
    min-height: 20px;
    margin-top: 0;
}

.input-group-text {
    background-color: transparent;
    color: var(--body-color);
    border-color: transparent;
}

.flex-gap {
    display: flex;
    align-items: baseline;
    gap: 2%;
}

.form-group {
    margin-bottom: $spacer;

    &.stay-inline {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }
}

app-checkbox-field {
    &.mb-0 {
        .form-group {
            margin-bottom: 0;
        }
    }
}

.stay-inline {
    label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
}

.input-group > .input-group-div {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

hr {
    border-top-color: var(--gray-300);
    opacity: 1;
}

.append-label {
    font-weight: $font-weight-normal;
    margin-bottom: 0;
    margin-top: math.div($spacer, 2);
}

.crop-settings {
    margin-bottom: $spacer;
    padding-bottom: $spacer !important;
    padding-top: math.div($spacer, 2) !important;
}

.crop-settings .form-inline .form-group {
    min-width: 180px;
    max-width: 180px;
}

@include media-breakpoint-down(lg) {
    .crop-settings .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }

    .crop-settings .form-inline .form-group .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .crop-settings .form-inline label {
        margin-bottom: 0;
    }
}

/* Ngx Slider */
.ngx-slider {
    margin-top: 16px !important;
    margin-bottom: 0 !important;

    .ngx-slider-bar {
        height: 10px !important;
        border-radius: $border-radius !important;
        background: var(--gray-200) !important;
    }

    .ngx-slider-selection {
        background: var(--blue-200) !important;
    }

    .ngx-slider-pointer {
        background: var(--primary) !important;
        top: -12px !important;

        &:after {
            display: none;
        }
    }

    .ngx-slider-bubble {
        z-index: 4;
        font-size: 14px !important;
        pointer-events: none;
        cursor: pointer;
        color: var(--gray-800) !important;

        &.ngx-slider-model-value,
        &.ngx-slider-model-high {
            color: var(--white) !important;
            top: -8px;
        }

        &.ngx-slider-limit {
            color: var(--gray-800) !important;
            bottom: 2px;
        }
    }

    &.error-slider,
    &.warning-slider,
    &.both-slider,
    &.reverse-slider,
    &.dual-error-slider,
    &.dual-warning-slider {
        margin-bottom: 24px !important;
    }

    &.error-slider {
        .ngx-slider-bar {
            background: var(--status-error) !important;
        }

        .ngx-slider-selection {
            background: var(--status-good) !important;
        }
    }

    &.warning-slider {
        .ngx-slider-bar {
            background: var(--status-warning) !important;
        }

        .ngx-slider-selection {
            background: var(--status-good) !important;
        }
    }

    &.both-slider {
        .ngx-slider-left-out-selection {
            .ngx-slider-bar {
                background: var(--status-good) !important;
            }
        }

        .ngx-slider-right-out-selection {
            .ngx-slider-bar {
                background: var(--status-error) !important;
            }
        }

        .ngx-slider-full-bar {
            .ngx-slider-bar {
                background: transparent !important;
            }
        }

        .ngx-slider-selection-bar {
            .ngx-slider-selection {
                background: var(--status-warning) !important;
            }
        }
    }

    &.reverse-slider {
        .ngx-slider-left-out-selection {
            .ngx-slider-bar {
                background: var(--status-error) !important;
            }
        }

        .ngx-slider-right-out-selection {
            .ngx-slider-bar {
                background: var(--status-good) !important;
            }
        }

        .ngx-slider-full-bar {
            .ngx-slider-bar {
                background: transparent !important;
            }
        }

        .ngx-slider-selection-bar {
            .ngx-slider-selection {
                background: var(--status-warning) !important;
            }
        }
    }

    &.dual-warning-slider {
        .ngx-slider-left-out-selection {
            .ngx-slider-bar {
                background: var(--status-good) !important;
            }
        }

        .ngx-slider-right-out-selection {
            .ngx-slider-bar {
                background: var(--gray-200) !important;
            }
        }

        .ngx-slider-full-bar {
            .ngx-slider-bar {
                background: transparent !important;
            }
        }

        .ngx-slider-selection-bar {
            .ngx-slider-selection {
                background: var(--status-warning) !important;
            }
        }
    }

    &.dual-error-slider {
        .ngx-slider-left-out-selection {
            .ngx-slider-bar {
                background: var(--status-good) !important;
            }
        }

        .ngx-slider-right-out-selection {
            .ngx-slider-bar {
                background: var(--gray-200) !important;
            }
        }

        .ngx-slider-full-bar {
            .ngx-slider-bar {
                background: transparent !important;
            }
        }

        .ngx-slider-selection-bar {
            .ngx-slider-selection {
                background: var(--status-error) !important;
            }
        }
    }
}

.rule-buttons {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
}

/* List */
ol li {
    margin-bottom: math.div($spacer, 4);
    word-wrap: break-word;
}

/* Type List */
.type-list {
    .list-group-item {
        color: var(--body-color);
        background-color: var(--white);
        border-color: var(--gray-300);
        border-bottom: 1px solid var(--gray-300);

        cursor: pointer;
        &:hover {
            background-color: var(--hover-bg-color);
        }

        h4 {
            color: var(--primary);
            margin-bottom: math.div($spacer, 4);
        }

        p {
            margin-bottom: math.div($spacer, 4);
            &.card-text:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/* Breadcrumbs */
.breadcrumb {
    background-color: var(--gray-200);
    &.active {
        color: var(--body-color);
    }
}

/* Pre */
pre {
    padding: $spacer;
    border: 1px solid var(--gray-300);
    background-color: var(--white);
    color: var(--body-color);
}

/* Alerts */
.alert-light {
    color: var(--body-color);
    background-color: var(--gray-100);
    border-color: var(--gray-300);

    .alert-link {
        color: var(--body-color);
    }

    .copy-btn {
        color: var(--body-color);
    }
}

.alert-danger {
    color: var(--red-900);
    background-color: var(--red-50);
    border-color: var(--red-100);
}

.alert-warning {
    color: var(--orange-900);
    background-color: var(--orange-50);
    border-color: var(--orange-100);
}

.alert-info {
    color: var(--cyan-900);
    background-color: var(--cyan-50);
    border-color: var(--cyan-100);
}

/* Dropdown */
.dropdown-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.dropdown-item-text {
    padding-left: math.div($spacer, 2);
    padding-right: math.div($spacer, 2);
    margin-bottom: 0;
    border-top: 1px solid var(--gray-300);
    color: var(--body-color);

    &:first-child {
        border-top: none;
    }
}

.dropdown-item {
    border-top: 1px solid var(--gray-300);

    &:first-child {
        border-top: none;
    }

    &.desc:after,
    &.asc:after {
        fill: var(--white);
        color: var(--white);
        content: "";
        display: inline-block;
        position: relative;
        left: 0.25rem;
        top: 0.2rem;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%23ffffff' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z' class=''%3e%3c/path%3e%3c/svg%3e");
        background-repeat: no-repeat;
        width: 1rem;
        height: 1rem;
    }

    &.desc:after {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%23ffffff' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z' class=''%3e%3c/path%3e%3c/svg%3e");
    }
}

/* Modal */
// old bootstrap 4.6 style
.close {
    color: var(--black);
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 var(--white);
    opacity: 0.5;

    &:hover {
        color: var(--black);
        opacity: 0.75;
    }
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.modal-xs {
    --bs-modal-width: 270px;
}

.modal-xxs {
    --bs-modal-width: 234px !important;
    max-width: 234px;
    margin-right: auto;
    margin-left: auto;
}

.modal-header {
    padding: math.div($spacer, 2) $spacer;
    border-color: var(--gray-300);
    background-color: var(--body-bg);

    .close {
        padding: math.div($spacer, 1.5) $spacer;
        margin: -1rem -1rem -1rem auto;
    }
}

.modal-body {
    background-color: var(--gray-50);

    p:last-child {
        margin-bottom: 0;
    }
}

.modal-footer {
    padding: math.div($spacer, 2) $spacer;
    border-color: var(--gray-300);
    background-color: var(--body-bg);
}

.modal-content {
    background-color: var(--gray-50);
    border-color: var(--gray-400);

    div.loading {
        opacity: 0.5;
    }
}

app-switch-channel-dialog,
app-assign-target-dialog {
    overflow: auto !important;

    form {
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
}

/* Modal that looks like form */
app-multiple-edit-dialog {
    display: flex !important;
    flex: 1 1 auto !important;
    overflow: auto !important;

    form {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: auto;
    }
}

.mimic-form {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 107;
    transition: none !important;

    .modal-dialog {
        min-height: 100%;
        min-width: 100%;
        max-height: unset;
        max-width: unset;
        margin: 0;
        justify-content: start;
        transition: none !important;

        .modal-content {
            border: none;
            border-radius: 0;
            height: 100%;
            background-color: var(--body-bg);
        }
    }
}

/* Mimic Tags */
ul.mimic-tags {
    padding-top: 5px;
    padding-left: 7px;
    color: var(--body-color);
    background-color: var(--gray-50);
    border-color: var(--gray-400);
    border-width: 1px;
    border-style: solid;
    border-radius: $border-radius;
}

.mimic-tags li {
    display: inline-block;
    background-color: var(--color-disabled);
    color: var(--body-color);
    font-size: 0.9em;
    margin-bottom: 5px;
    border-radius: 2px;
    margin-right: 5px;
    padding: 1px 5px;
    max-width: 100%;
}

.mimic-tags li:last-child {
    margin-bottom: 5px;
}

/* Popover */
.popover {
    border: 1px solid var(--gray-300);
    border-radius: math.div($spacer, 4);
    box-shadow: var(--box-shadow-sm);
    --bs-popover-bg: var(--white);

    .popover-body {
        padding: math.div($spacer, 2);
    }
}

h3.popover-header {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-semibold;
    text-transform: unset;
    background-color: var(--gray-100);
    border-bottom: 1px solid var(--gray-300);
}

/* Select */
select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%231976d2' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z' class=''%3e%3c/path%3e%3c/svg%3e") !important;
    background-repeat: no-repeat;
    background-position: bottom 50% right 0.75rem;
    background-size: 9px;
    background-color: var(--white) !important;
    border-color: var(--gray-400) !important;
    color: var(--body-color) !important;

    &::-ms-expand {
        display: none;
    }

    &.form-control[multiple] {
        background-image: none;
    }
}

/* Card */
.card {
    background-color: var(--white);
    border: 1px solid var(--gray-300);

    .card-header {
        padding-left: $spacer;
        padding-right: $spacer;
    }

    .card-body {
        padding: $spacer;

        &.no-padding-btm {
            padding-bottom: 0;
        }
    }

    form {
        .form-group:last-child {
            margin-bottom: 0;
        }
    }

    &.bg-light {
        background-color: var(--white) !important;
    }
}

form .card.bg-light .card-header {
    background-color: var(--primary);
    color: var(--white);
}

.card-sm {
    .card-header {
        padding: math.div($spacer, 2) math.div($spacer, 1.5);
    }

    .card-body {
        padding: math.div($spacer, 2) math.div($spacer, 1.5);
    }
}

/* Source Thumbnail */
app-source-thumbnail.bordered {
    border: 1px solid var(--gray-300);
}

app-source-thumbnail,
app-target-thumbnail,
.nodeThumbnail {
    .playButtonsOverlay {
        display: none;
        position: absolute;
        width: 100%;
        text-align: center;
        top: 50%;
        z-index: 2;
        margin-top: -13px;
    }

    .thumbnail {
        .aspect-ratio-fix {
            overflow: hidden;
        }
    }

    &:hover {
        .playButtonsOverlay {
            display: block;
        }
    }

    .videoErrorOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        top: 0;
        z-index: 1;
        color: white;

        .alert {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            margin: 0;
        }
    }
}

app-source-thumbnail-info,
app-target-thumbnail-info {
    display: flex;
    justify-content: space-between;
    flex: 1 1 auto;
    overflow: auto;
}

/* Tooltip */
.tooltip {
    font-size: $font-size-base;
    font-size: $small-font-size;

    &.hasThumbnail {
        .tooltip-inner {
            padding: 0;
            border-radius: 0;
            max-width: 300px;

            app-source-thumbnail,
            app-target-thumbnail {
                width: 280px;
            }
        }
    }

    .tooltip-inner {
        box-shadow: var(--box-shadow-sm);
        max-width: 260px;
        text-align: left;

        ul {
            li {
                &:last-child {
                    margin-bottom: 0;
                }

                label {
                    color: var(--white);
                    min-width: 120px;
                }
            }

            &.status-list-style {
                margin: 0;
                padding: 0;
                list-style: none;
            }
        }

        a {
            color: var(--white);
            text-decoration: underline;
        }

        hr {
            margin-top: math.div($spacer, 2);
            margin-bottom: math.div($spacer, 2);
        }
    }
}

/* Ng-Select */
.ng-select {
    &.multiline-options {
        div.ng-option {
            white-space: unset;
        }
    }

    &.form-control {
        // Remove default form-control styling on ng-select
        height: unset;
        padding: unset;
        font-size: unset;
        font-weight: unset;
        line-height: unset;
        color: unset;
        background-color: unset;
        background-clip: unset;
        border: unset;
        border-radius: unset;
        transition: unset;

        &.is-invalid {
            border-color: unset;
            padding-right: unset;
            background-image: unset;
            background-repeat: unset;
            background-position: unset;
            background-size: unset;
        }
    }

    .ng-select-container {
        border: 1px solid var(--gray-400) !important;
        color: var(--body-color) !important;
        background: var(--white) !important;

        .ng-value-container {
            .ng-placeholder {
                color: var(--gray-400) !important;
            }

            .ng-input > input {
                color: var(--body-color) !important;
            }
        }

        &:hover {
            box-shadow: none !important;
        }
    }

    &.ng-select-multiple {
        .ng-select-container {
            .ng-value-container {
                .ng-placeholder {
                    top: unset !important;
                }

                .ng-input > input {
                    color: var(--body-color) !important;
                }

                .ng-value {
                    background-color: var(--active-bg-color) !important;
                    color: var(--body-color) !important;

                    .ng-value-icon {
                        &.left {
                            border-right: 1px solid var(--blue-100) !important;
                        }

                        &:hover {
                            background-color: var(--blue-100) !important;
                            color: var(--body-color) !important;
                        }
                    }
                }
            }
        }
    }
}
.ng-select.bright {
    .ng-select-container {
        .ng-value-container {
            .ng-placeholder {
                color: var(--body-color) !important;
            }
        }
    }
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
    background-color: var(--color-disabled) !important;
    border-color: var(--color-disabled) !important;
}

.ng-select.ng-select-multiple.form-control-xs .ng-select-container .ng-value-container {
    padding-top: 3px !important;
    padding-left: 5px !important;

    .ng-value {
        margin-bottom: 3px !important;
        margin-right: 5px !important;
    }
}

.form-control-sm.ng-select.ng-select-single .ng-select-container {
    height: 31px !important;
}

.form-control-sm.ng-select .ng-select-container {
    min-height: auto !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border-color: $input-focus-border-color !important;
    box-shadow: $input-focus-box-shadow !important;
}

.ng-select.form-control.is-invalid .ng-select-container,
.is-invalid ng-select .ng-select-container {
    border: 1px solid var(--status-error) !important;
}

.ng-select.ng-select-opened > .ng-select-container {
    border-color: var(--gray-400) !important;
}

.is-invalid ng-select.ng-select.ng-select-opened > .ng-select-container {
    border-color: var(--status-error) !important;
}

.ng-dropdown-panel {
    z-index: 1101 !important;

    .ng-dropdown-panel-items {
        margin-bottom: 0;

        .ng-option {
            background-color: var(--white) !important;
            color: var(--body-color) !important;
            padding: math.div($spacer, 3) math.div($spacer, 2) !important;

            &.ng-option-selected,
            &.ng-option-selected.ng-option-marked {
                color: var(--body-color) !important;
                background-color: var(--active-bg-color) !important;
            }

            &.ng-option-marked {
                background-color: var(--hover-bg-color) !important;
                color: var(--body-color) !important;
            }

            &.ng-option-child {
                padding-left: math.div($spacer, 1.5) !important;
            }
        }

        .ng-optgroup {
            padding: math.div($spacer, 3) math.div($spacer, 2) !important;
            font-weight: $font-weight-semibold !important;
            color: var(--gray-600) !important;
            border-top: 1px solid var(--gray-300) !important;
            border-bottom: none !important;
        }
    }

    &.ng-select-bottom,
    &.ng-select-top {
        border-bottom-right-radius: $border-radius !important;
        border-bottom-left-radius: $border-radius !important;
        border-color: var(--gray-400) !important;
        background-color: var(--white) !important;
    }
}

.form-control-sm .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding-top: math.div($spacer, 4) !important;
    padding-bottom: math.div($spacer, 4) !important;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: var(--primary) transparent transparent !important;
}

.ng-select .ng-arrow-wrapper:hover .ng-arrow {
    border-top-color: var(--blue-800) !important;
}

.ng-select.ng-select-disabled .ng-arrow-wrapper .ng-arrow {
    border-color: var(--status-none) transparent transparent !important;
}

.ng-select.ng-select-disabled > .ng-select-container {
    opacity: $btn-disabled-opacity !important;
}

.ng-select.form-control.is-invalid .ng-arrow-wrapper .ng-arrow,
.is-invalid ng-select .ng-arrow-wrapper .ng-arrow {
    border-color: $red transparent transparent !important;
}

.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    border-color: transparent transparent var(--blue) !important;
}

.ng-select.ng-select-opened.form-control.is-invalid > .ng-select-container .ng-arrow,
.is-invalid ng-select.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    border-color: transparent transparent var(--status-error) !important;
}

.ng-select.ng-select-single.form-control-xs .ng-select-container {
    height: 22.8px !important;
}

.ng-select.form-control-xs .ng-select-container {
    min-height: 22.8px !important;
}

.ng-select.ng-select-single.form-control-xs .ng-select-container .ng-value-container .ng-input {
    top: 0 !important;
}

.ng-select.form-control-xs .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    padding-top: math.div($spacer, 4) !important;
    padding-bottom: math.div($spacer, 4) !important;
}

.ng-select .ng-clear-wrapper:hover .ng-clear {
    color: var(--status-error) !important;
}

body > .ng-dropdown-panel.form-control-xs {
    padding: 0;
    margin-left: math.div($spacer, 2) !important;
}

/* Pagination */
.pagination {
    .page-item {
        &.disabled {
            opacity: 1;

            .page-link {
                background-color: transparent;
                color: var(--gray-600);
                border-color: var(--gray-300);
            }
        }

        .page-link {
            color: var(--primary);
            background-color: var(--white);
            border-color: var(--gray-300);

            &:hover {
                color: var(--primary);
                background-color: var(--gray-200);
                border-color: var(--gray-300);
            }
        }

        &.active {
            .page-link {
                color: var(--color-white);
                background-color: var(--primary);
                border-color: var(--primary);
            }
        }
    }
}

.pagination-sm .page-link {
    font-size: $font-size-base;
}

/* Datepicker */
/* To/From Container */
.datepicker-container {
    z-index: 107;

    .arrow {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        right: $spacer * 3;

        &::after,
        &::before {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
        }

        &::before {
            top: 0;
            border-width: 0 0.5rem 0.5rem;
            border-bottom-color: var(--gray-300);
        }

        &::after {
            top: 1px;
            border-width: 0 0.5rem 0.5rem;
            border-bottom-color: var(--white);
        }
    }

    &.absolute {
        position: absolute;
        right: 0;
        top: 2rem;
    }

    .close-icon {
        position: absolute;
        right: -5px;
        top: 0;
        color: var(--secondary);
        cursor: pointer;
        font-size: 1rem;

        svg {
            background: var(--white);
            border-radius: math.div($spacer, 2);
        }
    }

    .datepicker-inner-container {
        border: 1px solid var(--gray-300);
        background: var(--white);
        margin-top: math.div($spacer, 2);
        border-radius: $border-radius;
        display: flex;
        width: 356px;

        .nav-pills {
            .nav-item {
                .nav-link {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    border-top-right-radius: 0;
                    border-bottom: 1px solid var(--gray-300);

                    &:hover {
                        background-color: var(--hover-bg-color);
                    }

                    &.active {
                        border-bottom: none;
                        color: var(--white);
                        background-color: var(--primary);
                    }
                }

                &:last-child {
                    .nav-link {
                        border-top-left-radius: 0;
                    }
                }
            }
        }

        .date-navigation {
            flex: 1 1 auto;

            .date-container {
                padding: 0;
            }
        }

        .date-presets {
            flex: 0 0 auto;
            border-left: 1px solid var(--gray-300);

            h5 {
                margin: 10px $spacer;
            }

            .list-group {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: 0;

                .list-group-item {
                    padding: math.div($spacer, 2) $spacer;
                    border: none;
                    border-top: 1px solid var(--gray-300);
                    background-color: var(--white);
                    cursor: pointer;
                    color: var(--primary);

                    &:hover {
                        background-color: var(--hover-bg-color);
                    }

                    &.active {
                        color: var(--white);
                        background-color: var(--primary);
                    }

                    &:last-child {
                        border-bottom: 1px solid var(--gray-300);
                        border-bottom-right-radius: 0;
                    }
                }
            }
        }
    }
}

.calendar-group {
    flex-shrink: 0;
    flex-grow: 1;
    max-width: 450px;
    min-width: 200px;

    &.d-flex {
        label {
            display: flex;
            flex: 0 0 auto;
            margin: 0;
            align-items: center;
            justify-content: center;
        }
    }
}

/* Ngb Datepicker */
zx-date-time-picker {
    &.bordered {
        ngb-datepicker {
            border: 1px solid var(--gray-400);
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .btn.btn-block.btn-outline-secondary {
            border-bottom-right-radius: $btn-border-radius;
        }

        .mt-auto.p-2 {
            border: 1px solid var(--gray-400);
            border-bottom: none;
        }
    }

    ngb-datepicker {
        display: block;
        padding: 0;
        width: 100%;
        border: 0;

        .ngb-dp-header {
            border-radius: 0;
            padding-top: 0;
            background: var(--gray-50);

            .ngb-dp-arrow-btn:focus {
                outline: none;
                box-shadow: none;
            }
        }

        .ngb-dp-month {
            width: 100%;
        }

        .ngb-dp-week {
            border-radius: 1rem;

            .ngb-dp-day {
                border-radius: 1rem;
            }
        }

        .ngb-dp-weekdays {
            background: var(--gray-50);
            border-radius: 0;
            border-bottom: 1px solid var(--gray-300);

            .ngb-dp-weekday {
                color: var(--body-color);
                height: 1.6rem;
            }
        }

        [ngbDatepickerDayView] {
            border-radius: 1rem;
        }

        .btn-light {
            &:hover {
                background-color: var(--gray-200) !important;
                color: var(--body-color);
                outline-color: var(--gray-400) !important;
            }

            &.bg-primary {
                background-color: var(--primary) !important;
            }
        }
    }

    .btn.btn-block.btn-outline-secondary {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: $border-radius;
    }

    ngb-timepicker {
        display: block;
        // height: 191px;
        height: 140px;

        .ngb-tp {
            min-width: 100%;
            width: 200px;

            .btn:focus,
            .btn.focus {
                box-shadow: none;
            }
        }
    }

    .d-flex {
        label {
            margin: 0;
        }
    }
}

/* Ngb Pagination */
ngb-pagination.mb-0 ul.pagination {
    margin-bottom: 0;
}

ngb-pagination.condensed .page-link {
    padding-left: math.div($spacer, 2);
    padding-right: math.div($spacer, 2);
}

ngb-accordion {
    &.panel-overflow-visible {
        .accordion-item {
            overflow: visible !important;
        }
    }

    &.accordion {
        --bs-accordion-color: var(--body-color);
        --bs-border-color: var(--gray-300);

        .accordion-item {
            @extend .card;
            margin-bottom: $spacer;
            background: var(--white);
            border-color: var(--gray-300);
            border-radius: 0;
            overflow-x: hidden;

            .accordion-header {
                padding: 0;
                border-bottom-color: transparent;

                div.is-invalid {
                    color: var(--status-error);

                    h5 {
                        color: var(--status-error);
                    }
                }

                .accordion-button {
                    @extend .btn;
                    @extend .btn-link;
                }

                .btn-link,
                .accordion-button {
                    box-shadow: none;
                    text-decoration: none;
                    width: 100%;
                    text-align: left;
                    background-color: var(--white);
                    border-radius: 0;
                    color: var(--primary);

                    &:hover {
                        background-color: var(--gray-200);
                        text-decoration: none;
                    }

                    &:focus,
                    &.focus {
                        text-decoration: none;
                        box-shadow: none;
                    }

                    .chevron-down,
                    .chevron-up {
                        position: absolute;
                        right: $spacer;
                        top: math.div($spacer, 2);
                    }

                    .chevron-down {
                        display: none;
                    }

                    &.collapsed {
                        .chevron-up {
                            display: none;
                        }

                        .chevron-down {
                            display: block;
                        }
                    }

                    h3 {
                        margin-bottom: 0;
                        margin-right: 1.5rem;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                        .badge {
                            font-family: $font-family-sans-serif;
                            font-size: $h6-font-size;
                            top: -3px;
                        }

                        small {
                            font-size: $h6-font-size;
                            font-weight: $font-weight-normal;
                        }
                    }
                }
            }

            .accordion-body {
                padding: $spacer;
                padding-top: math.div($spacer, 2);
                background: var(--white);

                app-object-incident-list,
                app-network-objects-state {
                    app-table-list {
                        .table-filter-container {
                            padding-left: $spacer;
                            padding-right: $spacer;
                        }
                    }
                }
            }
        }

        > .accordion-item:last-of-type {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        > .accordion-item:first-of-type {
            border-bottom: 1px solid var(--gray-300);
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }

        .form-group,
        app-checkbox-field {
            display: block;
            margin-bottom: math.div($spacer, 2);
        }

        ngb-panel.no-padding {
            .accordion-item {
                .accordion-body {
                    padding: 0;
                }
            }
        }

        ngb-panel.padding-5px {
            .accordion-item {
                .accordion-body {
                    padding: 5px;
                }
            }
        }
    }

    &.no-margin-btm.accordion {
        .accordion-item {
            margin-bottom: 0;
        }
    }

    &.no-padding.accordion {
        .accordion-item {
            .accordion-body {
                padding: 0;
            }
        }
    }

    &.no-padding-btm.accordion {
        .accordion-item {
            .accordion-body {
                padding-bottom: 0;
            }
        }
    }

    &.padding-5px.accordion {
        .accordion-item {
            .accordion-body {
                padding: math.div($spacer, 4);
            }
        }
    }

    &.allow-scroll.accordion {
        .accordion-item {
            .accordion-body {
                overflow: auto;
                height: 100%;
                min-height: 100px;
            }
        }
    }

    &.no-margin.accordion {
        .accordion-item {
            margin-bottom: 0;
            .accordion-header {
                .btn-link,
                .accordion-button {
                    border-radius: 0;
                    min-height: 35px;
                }
            }
        }

        > .accordion-item:first-child {
            border-bottom: none;
        }

        > .accordion-item:not(:first-child) {
            border-bottom: none;
        }

        > .accordion-item:last-child {
            border-bottom: 1px solid var(--gray-300);
        }
    }

    &.alerting-rules-accordion.accordion {
        .accordion-item {
            .accordion-header {
                .btn-link,
                .accordion-button {
                    h5 {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        padding-right: $spacer * 1.5;
                    }
                }
            }

            .accordion-body {
                background: var(--gray-50);
                border-top: 1px solid var(--gray-300);
            }
        }
    }
}

/* Ngx Gauge */
.reading-block,
.reading-label {
    line-height: 1;
}

.reading-block {
    margin-top: -5px;
}

.reading-label {
    margin-top: -10px;
    font-size: $font-size-base !important;
}

.dateRange {
    width: auto !important;
}

@include media-breakpoint-down(sm) {
    .datepicker-container.absolute {
        right: -55px;
    }
}

/* Ngx UI Tour */
.ngx-ui-tour_backdrop {
    z-index: 1100 !important;
}

.cdk-overlay-container {
    z-index: 1101 !important;
}

.cdk-overlay-connected-position-bounding-box:has(.tour-step) {
    z-index: 1101;
}

/* Picker Container */
.picker-container {
    z-index: 107;

    &.fixed {
        position: fixed;
        margin-left: -$spacer;
    }

    &.relative {
        position: relative;
    }

    &.absolute {
        position: absolute;
    }

    .arrow {
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem;
        left: 33%;
        margin-left: 0;

        &::after,
        &::before {
            position: absolute;
            display: block;
            content: "";
            border-color: transparent;
            border-style: solid;
        }

        &::before {
            top: 0;
            border-width: 0 0.5rem 0.5rem;
            border-bottom-color: var(--gray-300);
        }

        &::after {
            top: 1px;
            border-width: 0 0.5rem 0.5rem;
            border-bottom-color: var(--white);
        }
    }

    .close-icon {
        position: absolute;
        right: -5px;
        top: 0;
        color: var(--secondary);
        cursor: pointer;
        font-size: 1rem;
    }

    .picker-content {
        border: 1px solid var(--gray-300);
        background: var(--white);
        padding: 0;
        margin-top: math.div($spacer, 2);
        border-radius: $border-radius;

        &.muteUntil {
            zx-date-time-picker {
                .btn.btn-block.btn-outline-secondary {
                    border-radius: 0;
                }
            }
        }

        ngb-datepicker {
            border: none;
        }

        zx-date-time-picker {
            ngb-datepicker {
                .ngb-dp-header {
                    border-top-left-radius: $btn-border-radius;
                    border-top-right-radius: $btn-border-radius;
                }
            }

            .btn.btn-block.btn-outline-secondary {
                border-bottom-left-radius: $btn-border-radius;
                border-bottom-right-radius: $btn-border-radius;
            }
        }
    }
}

/* Title Bar */
.title-bar {
    flex: 0 0 auto;
    padding: math.div($spacer, 2) $spacer;
    border-bottom: 1px solid var(--gray-300);
    background: var(--white);

    .title {
        display: inline-block;
        height: 100%;

        h1 {
            margin-top: 4px;
            margin-bottom: 2px;

            small {
                font-size: $tiny-font-size;
                font-weight: $font-weight-normal;
                opacity: $btn-disabled-opacity;
            }
        }
    }

    .actions {
        float: right;
    }
}

form .title-bar {
    padding-right: $spacer * 2;
    overflow: hidden;
}

/* Form Nav Bar */
.form-nav-bar {
    flex: 0 0 auto;
    border-bottom: 1px solid var(--gray-300);
    background: var(--white);

    .nav-link {
        padding: math.div($spacer, 3) $spacer;
        height: 100%;

        &:hover {
            background-color: var(--hover-bg-color);
        }

        &.active {
            border-bottom: math.div($spacer, 8) solid var(--primary);
        }

        &.error {
            color: var(--status-error);
            font-weight: $font-weight-semibold;
        }
    }
}

.form-nav-bar .nav-link.active.error {
    border-bottom: math.div($spacer, 8) solid var(--status-error);
}

app-announcements-configurations {
    .title-bar .title {
        height: auto;
    }
}

/* List */
.panel-container {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
}

app-cluster-list,
app-source-list {
    .panel-container {
        position: relative;
    }
}

#panel-container {
    overflow-x: hidden !important;
}

.left-container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 24rem;
    overflow-x: auto;
    overflow-y: hidden;

    &.leftSideContracted {
        overflow: hidden;

        .title-bar {
            flex-shrink: 1;
            white-space: nowrap;

            .actions {
                display: none;
            }
        }
    }

    &.rowSelected {
        flex-grow: 0;
    }

    &.new-left-container {
        overflow-x: hidden;

        &.rowSelected {
            transition: all 100ms linear 0s;

            &.resizing {
                transition: none;
            }
        }
    }
}

.new-right-container {
    display: flex;
    flex: 1;
    min-height: 0;
    overflow-x: hidden;
    transition: all 100ms linear 0s;

    .details-panel {
        min-height: 0;

        .details-content {
            display: flex;
        }
    }

    &.rightSideContracted {
        div {
            overflow: hidden;
        }

        .details-content {
            opacity: 0;
        }
    }
}

.right-container,
.new-right-container {
    &.resizing iframe {
        pointer-events: none;
    }
}

zx-list-resize,
.resize-container {
    position: relative;
    width: 0;
    border-left: 1px solid var(--gray-300);
    text-align: center;
}

.resize-container {
    display: block;
}

zx-list-resize .resize-button,
.resize-container .resize-button {
    position: absolute;
    background-color: var(--secondary);
    border-left: 1px solid var(--gray-300);
    border-radius: 0.5rem;
    cursor: col-resize;
    top: 50%;
    width: 1rem;
    height: 3rem;
    margin-top: -1.5rem;
    margin-left: -9px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 105;
}

zx-list-resize .resize-button fa-icon,
.resize-container .resize-button fa-icon {
    position: relative;
    top: 0.8rem;
    color: var(--white);
    pointer-events: none;
}

.resizing {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    iframe {
        pointer-events: none;
    }
}

.ui-resizable-resizing {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    iframe {
        pointer-events: none;
    }
}

.list-panel {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: $spacer;
    overflow: auto;

    .list-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;

        .list-top {
            flex: 0 0 auto;
        }

        .list-content {
            flex: 0 1 auto;
        }

        .list-bottom {
            flex: 0 0 auto;
            overflow: auto;

            .pagination {
                margin: 0;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .left-container.rowSelected {
        flex: 0 0 auto !important;
        height: 0;
    }

    .panel-container {
        flex-direction: column;
    }

    .list-panel.rowSelected {
        display: none;
    }
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .form-group {
        &.searchBox {
            flex-grow: 1;
            flex-shrink: 1;

            &.full-width {
                flex-basis: 100%;
                margin-bottom: math.div($spacer, 2) !important;

                .input-group {
                    padding: 0;
                }
            }

            .input-group {
                width: 100%;
                padding-right: $spacer;

                input {
                    width: 0;
                }
                .btn-outline-light {
                    border-color: var(--gray-400);
                    border-left-color: var(--white);
                    color: var(--body-color);
                    background-color: var(--white);

                    &:not(:disabled):not(.disabled):active,
                    &:not(:disabled):not(.disabled).active {
                        color: var(--body-color);
                        background-color: var(--white);
                        border-color: var(--gray-400);
                        border-left-color: var(--white);
                    }
                }
            }

            &.highlight {
                .input-group {
                    input {
                        border-color: var(--status-error);
                    }
                    .btn-outline-light {
                        border-color: var(--status-error);
                        border-left-color: var(--white);
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    .form-inline {
        .form-group {
            &.searchBox {
                label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 0;
                }

                .form-control {
                    display: inline-block;
                    width: auto;
                    vertical-align: middle;
                }
            }
        }

        .form-group.searchBox .input-group,
        .form-inline .custom-select {
            width: auto;
        }
    }
}

@include media-breakpoint-down(xxs) {
    .form-inline {
        .form-group {
            &.searchBox {
                width: 100%;
                .input-group {
                    padding-right: 0;
                }
            }
        }
    }
}

/* Details */
.details-panel {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;

    &.new-page {
        spinner-animation {
            display: block;
            position: relative;
            top: 47.5%;
        }
    }

    .details-action-bar {
        flex: 0 0 auto;
        padding: math.div($spacer, 2) $spacer 0 math.div($spacer, 2);
        border-bottom: 1px solid var(--gray-300);
        background-color: var(--white);

        .details-title {
            display: inline-block;
            max-width: 100%;

            h2 {
                display: inline-block;
                margin: 0;
                padding: 0;
                width: 100%;
                padding-top: 1px;

                .btn {
                    position: relative;
                    top: -1px;
                }
            }
        }

        .details-buttons {
            float: right;

            zx-mute {
                display: block;
                float: left;
            }
        }
    }

    .details-content-accordions {
        flex: 1 1 auto;
        overflow: auto;
        padding: $spacer $spacer 0 $spacer;
    }

    .details-content {
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
        min-height: 0;

        app-details-section {
            display: flex;
            flex-grow: 0;
            flex-shrink: 1;
            flex-basis: 0%;
            height: 100%;
            transition: height 100ms linear 0s;

            &.hidden {
                height: 0;
                z-index: 105 !important;

                &.sticky {
                    border: none;
                }
            }
        }

        .sticky {
            position: sticky;
            background: var(--gray-100);
            border-bottom: 1px solid var(--gray-300);
            z-index: 104;
            top: 0;
        }

        .primary {
            background: var(--gray-100);
            border-bottom: 1px solid var(--gray-300);
        }

        .primary:not(.sticky) + app-details-section {
            .show-button {
                top: -12px;
                border: 1px solid var(--gray-300) !important;
                border-radius: var(--bs-btn-border-radius) !important;
            }
        }

        app-widget-section-header {
            background-color: transparent !important;
            border-bottom: none !important;
        }

        app-widget-section {
            display: flex;
            transition: none;
            flex: 1;
        }

        .scroll-content {
            display: flex;
            flex: 1;
            flex-direction: column;
            min-height: 0;
            overflow-y: auto;
            overflow-x: hidden;

            zx-active-states {
                .alert {
                    margin: 0;
                    border-radius: 0;
                }
            }

            .alert {
                margin: 0;
                border-radius: 0;
            }
        }
    }

    .details-content-tabs {
        display: flex;
        overflow: auto;
        padding: 0;
        flex: 1 1 auto;
        position: relative;

        .nav-tabs {
            // For horizontal scroll
            overflow-x: auto;
            overflow-y: hidden;
            flex: 0 0 auto;
            flex-wrap: nowrap;
            border-bottom: 0;

            &::-webkit-scrollbar {
                // For horizontal scroll
                display: none;
            }

            .nav-item {
                // For horizontal scroll
                flex: 0 0 auto;
            }
        }

        // For horizontal scroll
        .scroll-left-btn,
        .scroll-right-btn {
            height: 3rem;
            width: $spacer;
            top: 0;
            padding: math.div($spacer, 1.5) 0 0 0;
            border-radius: 0;
            z-index: 1;
            background-color: var(--gray-200);
            color: var(--secondary);
            border-bottom: 1px solid var(--white);
        }

        // For horizontal scroll
        .scroll-left-btn {
            position: absolute;
            left: 0;
            border-right: 1px solid var(--gray-300);
        }

        .scroll-right-btn {
            position: absolute;
            right: 0;
            border-left: 1px solid var(--gray-300);
        }
    }
}

.dark {
    .details-panel {
        .details-content {
            .primary {
                border-bottom: 1px solid var(--gray-200);
            }
        }
    }

    .flex-item {
        &.primary-data {
            border: 1px solid var(--gray-200) !important;

            &:hover {
                border: 1px dashed var(--gray-300) !important;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .details-panel {
        .details-content {
            .sticky {
                position: static;
            }

            app-widget-section-header.sticky {
                position: sticky;
                top: 0 !important;
            }
        }
    }
}

.details-form {
    .form-group {
        margin-bottom: math.div($spacer, 1.5);
        span {
            word-break: break-word;
        }
    }

    h5 {
        margin-bottom: 1px;
        font-size: 13px;
        color: var(--gray-800);
    }

    .flex-basis-min {
        flex-basis: 300px;
    }
}

/* Events */
.event-panel {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    flex-direction: column;

    .event-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        overflow: auto;

        .event-list {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            padding: $spacer;
            padding-top: 0;

            .list-content {
                flex: 0 1 auto;
            }

            .list-bottom {
                flex: 0 0 auto;
                overflow: auto;

                .pagination {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.event-filters {
    flex: 0 0 auto;
    padding: $spacer $spacer 0 $spacer;

    .showFilter {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0 !important;
    }

    .filter-container {
        background-color: var(--gray-100);
        padding: $spacer;
        padding-bottom: 0;
        border: 1px solid var(--gray-300);
        border-radius: 0 math.div($spacer, 4) math.div($spacer, 4) math.div($spacer, 4);
        position: relative;
        margin-bottom: $spacer;

        .closeFilter {
            cursor: pointer;
            position: absolute;
            top: math.div($spacer, 2);
            right: math.div($spacer, 2);
            color: var(--gray-600);

            &:hover {
                color: var(--gray-700);
            }
        }
    }
}

/* Tabs */
.nav {
    --bs-nav-link-color: var(--primary);
    --bs-nav-link-hover-color: var(--primary);
}

.nav-tabs {
    background-color: var(--gray-200);
    padding: math.div($spacer, 2) math.div($spacer, 2) 0 math.div($spacer, 2);
    border-color: var(--gray-300);

    .nav-item {
        font-family: $font-family-title;
        font-weight: $font-weight-bold;
        line-height: 1.4;

        .nav-link {
            fa-icon {
                svg[data-icon="align-justify"],
                svg[data-icon="exclamation-triangle"],
                svg[data-icon="share"],
                svg[data-icon="sitemap"],
                svg[data-icon="calendar-alt"] {
                    position: relative;
                    top: 1px;
                    vertical-align: unset;
                }
            }

            .badge {
                position: relative;
                top: -1px;
                font-family: $font-family-sans-serif;
                vertical-align: middle;
            }
        }
    }

    .nav-link {
        border-top: math.div($spacer, 4) solid transparent;
        padding-left: 12px;
        padding-right: 12px;

        &:hover,
        &:focus {
            position: relative;
            top: 3px;
            background-color: var(--gray-300);
            border-color: var(--gray-300);
            border-top-width: 1px;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link,
    .nav-link.active:hover,
    .nav-link.active:focus {
        color: var(--secondary);
        background-color: var(--white);
        border-bottom-color: var(--white);
        border-top: math.div($spacer, 4) solid var(--secondary);
        border-left-color: transparent;
        border-right-color: transparent;
        top: 0;
    }
}

/* Tab Layout */
.tab-container {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
}

.details-panel .details-content-tabs .hasScroll .nav-tabs {
    padding-left: $spacer * 1.25;
    border-bottom: 0;
}

.details-panel .details-content-tabs .hasScroll .nav-tabs .nav-item:last-child .nav-link {
    margin-right: $spacer * 1.25;
}

.tab-container.with-router .nav-tabs .nav-link a {
    display: block;
    padding: math.div($spacer, 2) $spacer;
}

.tab-container.with-router .nav-tabs .nav-link.active {
    background-color: var(--gray-50) !important;
    border-bottom-color: var(--gray-50) !important;
}

.tab-container.with-router .nav-tabs .nav-link.active a {
    color: var(--secondary) !important;
}

.tab-container.with-router .nav-tabs .nav-link a:hover {
    text-decoration: none;
}

.tab-container .tabset,
.details-panel .details-content-tabs .tabset,
.details-panel .details-content-tabs ngb-tabset,
.tab-container ngb-tabset {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1 1 auto;
}

.details-panel .details-content-tabs ngb-tabset,
.details-panel .details-content-tabs .tabset {
    background-color: var(--white);
}

.details-panel .details-content-tabs .tab-content {
    background-color: var(--white);
    flex: 1 1 auto;
    overflow: auto;
    display: flex;
}

.tab-content > .active {
    padding-top: $spacer;
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
}

.tab-content > .active .list-panel {
    padding-top: 0;
}

.tab-container .tab-content {
    display: flex;
    overflow: auto;
    flex: 1 1 auto;
}

.tab-container .tab-content > .active {
    flex: 0 0 auto;
    width: 100%;
}

.details-panel .details-content-tabs .card {
    margin-bottom: $spacer;
}

.details-panel .details-content-tabs .card .form-group {
    margin-bottom: math.div($spacer, 2);
}

.visualizationLoading {
    position: absolute;
    top: 50%;
    left: 50%;

    spinner-animation {
        height: 100%;
        display: flex;
    }
}

ngb-accordion .visualizationLoading {
    padding-top: 1px;
}

.visualizationUpdating {
    position: absolute;
    top: 50%;
    left: 50%;
}

#noModel {
    position: relative;
    top: 47%;
}

/* Thumbnail */
.thumbnail {
    position: relative;
    border: 1px solid var(--gray-300);
    border-radius: $border-radius;
    padding: math.div($spacer, 4);

    .aspect-ratio-fix {
        width: 100%;
        position: relative;
        padding-bottom: 56.25%;

        .img-container {
            position: absolute;
            height: 100%;
            width: 100%;
            border: 1px solid var(--gray-300);

            spinner-animation {
                position: absolute;
                top: 50%;
                left: 50%;
            }

            img,
            video {
                position: absolute;
                height: 100%;
                width: auto;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            img.superwide,
            video.superwide {
                height: auto;
                width: 100%;
            }

            content-loading-animation {
                position: absolute;
                z-index: 2;
            }
        }
    }

    .overlay-text {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 25px;
        left: 0;
        z-index: 1;
        display: flex;

        &.isSmall {
            .alert {
                margin: 0 !important;
                padding: 0 !important;
                border-color: transparent;
                background-color: transparent;
            }
        }

        .alert-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            margin-bottom: 25px;

            .alert-container-inner {
                display: flex;
                justify-content: center;
                align-items: start;
                flex-wrap: wrap;

                .alert {
                    padding: math.div($spacer, 4) math.div($spacer, 2);
                    margin: math.div($spacer, 4);
                    text-align: center;
                }
            }
        }
    }

    .overlay-timestamp {
        position: absolute;
        bottom: 5px;
        right: 5px;
        background-color: $black;
        color: var(--zixi-green);
        padding: math.div($spacer, 8);
        font-weight: $font-weight-semibold;
    }

    .overlay-audio {
        position: absolute;
        bottom: 0;
        left: 5px;
    }

    .overlay-audio.horizontal {
        transform: rotate(90deg);
        left: 40px;
        bottom: -35px;
    }

    .overlay-bitrate {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: $black;
        color: var(--zixi-green);
        padding: math.div($spacer, 8);
        font-weight: $font-weight-semibold;
    }
}

app-source .thumbnail .overlay-text .alert {
    margin-top: 0;
}

app-shared-source-details app-source-thumbnail .thumbnail {
    border-radius: 0;
    padding: 0;
}

/* WebRTC Video */
app-source-webrtc-video {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    position: relative;

    content-loading-animation {
        position: absolute;
        left: 0;
        top: 50%;
    }

    .webrtc-video-container {
        height: 100%;
        width: 100%;
        text-align: center;

        .webrtc-error-message {
            position: absolute;
            top: 45%;
            width: 100%;
            color: white;
            font-weight: $font-weight-bold;
        }

        .aspect-ratio-fix {
            height: 100%;

            div {
                height: 100%;
            }
        }
    }
}

/* jsTree */
.jstree-default .jstree-clicked,
.jstree-default .jstree-hovered {
    background: var(--gray-200) !important;
    border-radius: 0 !important;
    box-shadow: none !important;
}

.jstree-default .jstree-node,
.jstree-default .jstree-icon {
    background-image: url(assets/images/dark-32px.png) !important;
}

#cy {
    display: none;
}

div[id^="dchart"] {
    height: 0;
    width: 0;

    svg {
        height: 0;
        width: 0;
    }
}

/* Dragula */
/* in-flight clone */
.gu-mirror {
    position: fixed !important;
    margin: 0 !important;
    z-index: 9999 !important;
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    pointer-events: none;
    list-style: none;
    cursor: grabbing !important;

    &.flex-content-li {
        display: flex;
        overflow: hidden;
        height: auto !important;
        padding: 0 !important;

        .widget-container {
            display: none;
        }
    }
}

/* high-performance display:none; helper */
.gu-hide {
    left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);

    &.flex-content-li {
        display: flex;
        overflow: hidden;
        height: 560px !important;
    }

    zx-history-graph {
        display: none;
    }
}

/* Map - Form related */
zx-location-search input {
    text-overflow: ellipsis;
}

zx-location-search .location-results-list ul {
    margin: 0;
    padding: 0;
}

/* Map */
.map-container.hasCluster .leaflet-container .leaflet-map-pane .leaflet-overlay-pane {
    display: none;
}

.address-lookup,
.marker-lookup {
    width: 300px;
    margin-top: $spacer;
    margin-left: $spacer;
    padding-right: $spacer;
    padding-bottom: $spacer;

    .form-control {
        background: rgba(255, 255, 255, 0.9);
        border: 1px solid var(--gray-400);
        color: var(--body-color);

        &:focus {
            border-color: none;
            box-shadow: none;
            background: $white;
        }
    }

    .input-group {
        z-index: 2;

        .btn-outline-light {
            border-left-color: var(--gray-300) !important;

            /* border-color: $dark-border;
            border-left-width: 0;
            background-color: $blue-gray-900;
            color: $dark-text; */
        }
    }

    .btn {
        opacity: 0.9;
    }

    &.hasResults .input-group > .input-group-prepend > .btn:first-child {
        border-bottom-left-radius: 0;
    }

    &.hasResults .input-group > .btn:last-child {
        border-bottom-right-radius: 0;
    }
}

.marker-lookup input {
    min-height: 28px;
}

.object-details {
    min-width: 280px;
    max-width: 400px;
    height: 100%;
    overflow: auto;
    padding: $spacer;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: $leaflet-box-shadow;
    border-bottom-right-radius: $border-radius;

    .close {
        position: absolute;
        right: $spacer;
        top: $spacer;
        z-index: 1;
    }

    h5 {
        margin-bottom: 0;
        font-size: $h6-font-size;
    }

    .form-group {
        margin-bottom: math.div($spacer, 2);

        .col-12 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }

    .alert {
        border: none;
        background: none;
        padding: 0;
        margin-bottom: math.div($spacer, 2);
    }
}

.map-legend {
    width: 410px;
    font-size: 13px;
    padding: math.div($spacer, 2);

    .marker-clusters {
        position: relative;
        height: 40px;
        width: 125px;

        .marker-cluster {
            transform-origin: 50% 50% !important;
            left: 25px;
            top: 20px;
            pointer-events: none;
        }
    }

    h4 {
        font-size: 14px;
    }

    h5 {
        font-size: 13px;
    }

    ul {
        padding: 0;
        margin-bottom: math.div($spacer, 2);
        list-style-type: none;
    }

    .status-block {
        display: inline-block;
        width: $spacer * 2;
        height: $spacer;
        border-radius: $border-radius;
        border: 1px solid var(--white);
        opacity: 1 !important;

        &.good {
            background-color: var(--status-good);
        }

        &.warning {
            background-color: var(--status-warning);
        }

        &.error {
            background-color: var(--status-error);
        }

        &.disabled {
            background-color: var(--status-none);
        }
    }

    .customMarker {
        display: inline-block;
        margin-top: 0 !important;

        div {
            font-size: 14px;
            box-shadow: none;
            left: 0;
            top: 0;
        }
    }
}

@include media-breakpoint-down(sm) {
    .map-legend {
        width: auto;
    }
}

.extra-map-controls {
    pointer-events: all;
    margin-top: $spacer;
    margin-right: $spacer * 3;

    .btn {
        border-radius: math.div($spacer, 4);
    }

    .btn-outline-secondary {
        width: 32px;
        height: 32px;
        box-shadow: $leaflet-box-shadow;
        padding: 0;
        text-align: center;
    }
}

/* Leaflet */
.leaflet-container {
    font: unset !important;
    background: var(--gray-300) !important;

    a {
        color: var(--primary) !important;
    }
}

.leaflet-center {
    position: absolute;
    z-index: 1000;
    font-weight: $font-weight-semibold;
    background: transparent;
    top: 50%;
    left: 50%;
    color: rgba(0, 0, 0, 0.5);
}

.leaflet-top {
    top: 0 !important;

    &.leaflet-left {
        overflow: visible;
        pointer-events: all !important;
        z-index: 1002 !important;

        &.showingDetails {
            height: 100%;
        }
    }

    &.leaflet-right {
        right: $spacer !important;
    }

    .leaflet-control {
        margin-top: $spacer !important;
    }
}

.leaflet-bottom {
    &.leaflet-left {
        overflow: visible;
        z-index: 1001 !important;

        &.showNewObjects {
            height: 100%;
        }
    }

    &.leaflet-right {
        z-index: 999 !important;
    }

    .leaflet-control {
        margin-bottom: 0 !important;

        a {
            color: var(--primary);
        }
    }
}

.leaflet-right .leaflet-control {
    margin-right: 0 !important;
}

.leaflet-left .leaflet-control {
    margin-left: 0 !important;
}

.leaflet-container .leaflet-control-attribution {
    background: rgba(255, 255, 255, 0.5);
    font-size: $h6-font-size;
}

.leaflet-touch {
    .leaflet-bar {
        a {
            background-color: var(--body-bg);

            &:first-child {
                border-top-left-radius: $border-radius !important;
                border-top-right-radius: $border-radius !important;
            }

            &:last-child {
                border-bottom-left-radius: $border-radius !important;
                border-bottom-right-radius: $border-radius !important;
                border-bottom: none !important;
            }
        }
    }

    .leaflet-control-layers,
    .leaflet-bar {
        border-radius: $border-radius !important;
        border: 1px solid var(--gray-400) !important;
        box-shadow: $leaflet-box-shadow;
    }
}

.leaflet-bar {
    a {
        color: var(--body-color) !important;

        &:hover {
            background-color: var(--gray-200) !important;
        }
    }

    a,
    a:hover {
        border-bottom: 1px solid var(--gray-400) !important;
    }
}

.leaflet-control-layers-toggle {
    width: 30px !important;
    height: 30px !important;
}

.leaflet-retina .leaflet-control-layers-toggle {
    background-size: 20px 20px !important;
    border-radius: $border-radius;
    background-color: var(--body-bg);
}

.leaflet-control-layers {
    box-shadow: $leaflet-box-shadow !important;

    label {
        font-weight: $font-weight-normal;
    }
}

.leaflet-control-layers-expanded {
    padding: 0 !important;
    background: rgba(255, 255, 255, 0.9) !important;
    color: var(--body-color) !important;

    .leaflet-control-layers-list {
        padding: math.div($spacer, 2) !important;
        font-size: 13px;
    }
}

.leaflet-draw-toolbar .leaflet-draw-edit-edit.leaflet-disabled {
    display: none;
}

.leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
    display: none;
}

.leaflet-control-layers-base {
    label {
        line-height: 1.7;

        div {
            padding-left: 1.25rem;
            position: relative;
            z-index: 1;
            display: block;
            min-height: 1.3125rem;

            input[type="radio"] {
                position: absolute;
                left: 0;
                z-index: -1;
                width: 1rem;
                height: 1.15625rem;
                opacity: 0;
            }

            span {
                display: inline-block;
                position: relative;
                margin-bottom: 0;
                vertical-align: top;

                &::before {
                    border-radius: 50%;
                    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    position: absolute;
                    top: 0.15625rem;
                    left: -1.5rem;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    pointer-events: none;
                    content: "";
                    background-color: #fff;
                    border: #9e9e9e solid 1px;
                }

                &::after {
                    position: absolute;
                    top: 0.15625rem;
                    left: -1.5rem;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    content: "";
                    background: no-repeat 50% / 50% 50%;
                }

                &::before,
                &::after {
                    left: -1.25rem;
                }
            }
        }
    }
}

.leaflet-control-layers-base label div input[type="radio"]:checked ~ span::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.leaflet-control-layers-base label div input[type="radio"]:checked ~ span::before {
    color: #fff;
    border-color: #1976d2;
    background-color: #1976d2;
}

.leaflet-control-layers-overlays {
    label {
        div {
            position: relative;
            z-index: 1;
            display: block;
            min-height: 1.3125rem;
            padding-left: 1.25rem;

            input[type="checkbox"] {
                position: absolute;
                left: 0;
                z-index: -1;
                width: 1rem;
                height: 1.15625rem;
                opacity: 0;
                margin-top: 0;
                top: 0;
            }

            span {
                display: inline-block;
                position: relative;
                margin-bottom: 0;
                vertical-align: top;
                line-height: 1.6;

                &::before {
                    border-radius: 0.25rem;
                    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    position: absolute;
                    top: 0.15625rem;
                    left: -1.5rem;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    pointer-events: none;
                    content: "";
                    background-color: #fff;
                    border: #9e9e9e solid 1px;
                }

                &::after {
                    position: absolute;
                    top: 0.15625rem;
                    left: -1.5rem;
                    display: block;
                    width: 1rem;
                    height: 1rem;
                    content: "";
                    background: no-repeat 50% / 50% 50%;
                }

                &::before,
                &::after {
                    left: -1.25rem;
                }
            }

            input[type="checkbox"]:checked ~ span::before {
                color: #fff;
                border-color: #1976d2;
                background-color: #1976d2;
            }

            input[type="checkbox"]:checked ~ span::after {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
            }
        }
    }
}

.leaflet-tooltip {
    padding: math.div($spacer, 3) !important;
    background-color: $blue-gray-800 !important;
    border: 1px solid $blue-gray-800 !important;
    border-radius: $border-radius !important;
    color: $white !important;
    box-shadow: $leaflet-box-shadow !important;
    font-size: 13px !important;

    span.item {
        float: left;
    }

    .status-good {
        color: $dark-status-good;
    }

    .status-bad,
    .status-error {
        color: $dark-status-error;
    }

    .status-warning {
        color: $dark-status-warning;
    }

    .status-none,
    .status-no,
    .status-disabled,
    .status-pending {
        color: $dark-status-none;
    }
}

.leaflet-tooltip-left:before {
    border-left-color: $blue-gray-800 !important;
}

.leaflet-tooltip-top:before {
    border-top-color: $blue-gray-800 !important;
}

.leaflet-tooltip-right:before {
    border-right-color: $blue-gray-800 !important;
}

.leaflet-tooltip-bottom:before {
    border-bottom-color: $blue-gray-800 !important;
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
    background: $white !important;
    color: $body-color !important;
    box-shadow: $leaflet-box-shadow;
}

.leaflet-container a.leaflet-popup-close-button {
    color: var(--gray-300);
}

.leaflet-popup-content-wrapper {
    border-radius: $border-radius !important;

    .leaflet-popup-content {
        font-size: 13px;
        margin: math.div($spacer, 2) math.div($spacer, 2) !important;
        line-height: 1.5 !important;
        min-width: 116px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: math.div($spacer, 4);

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    cursor: pointer;
                    white-space: nowrap;

                    &:hover {
                        text-decoration: none;
                    }
                }

                span.item {
                    color: $body-color;
                    display: none;
                }
            }
        }
    }
}

.shadow-custom {
    box-shadow: $leaflet-box-shadow;
    border-radius: $border-radius;

    .btn.disabled,
    .btn:disabled {
        opacity: 1 !important;
    }

    .btn-primary.disabled,
    .btn-primary:disabled {
        color: $white !important;
        background-color: $gray-400 !important;
        border-color: $gray-400 !important;
    }
}

.customMarker {
    margin-top: -22px !important;
    margin-left: 0 !important;

    div {
        display: block;
        background-color: $body-color;
        width: 2rem;
        height: 2rem;
        left: -1rem;
        top: -1rem;
        position: relative;
        border-radius: 1.5rem 3.5rem 0 3.5rem;
        transform: rotate(45deg);
        border: 1px solid $white;
    }

    &.sourceMarker,
    &.targetMarker {
        margin-top: 0 !important;

        div {
            border-radius: 3.5rem;
            border-style: dashed;
        }
    }

    &.selected div {
        border-width: 2px;
        box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.25);
        width: 2.1rem;
        height: 2.1rem;
        left: -1.1rem;
        top: -1.1rem;
    }

    &.status-good div {
        background-color: $status-good;
    }

    &.status-warning div {
        background-color: $status-warning;
    }

    &.status-error div,
    &.status-bad div {
        background-color: $status-error;
    }

    &.status-info div {
        background-color: $status-info;
    }

    &.status-pending div,
    &.status-disabled div,
    &.status-none div,
    &.status-no div {
        background-color: $status-none;
    }

    div span {
        position: relative;
        display: inline-block;
        left: 0.275rem;
        top: 0.675rem;
        color: $white;
        font-weight: $font-weight-bold;
        transform-origin: 0 0;
        transform: rotate(-45deg);
    }
}

.marker-cluster {
    background-color: rgba(224, 224, 224, 0.5) !important;

    div {
        background-color: rgba(255, 255, 255, 0.75) !important;
        color: $body-color !important;
        font-weight: $font-weight-bold !important;
        font: 11px $font-family-sans-serif !important;
        height: 30px !important;
        margin-left: 3px !important;
        margin-top: 3px !important;
        background-color: rgba(255, 255, 255, 0.75) !important;
        border-radius: 15px !important;
    }

    &.marker-cluster-small {
        margin-left: -18px !important;
        margin-top: -18px !important;
        width: 52px !important;
        height: 36px !important;
        border-radius: 18px !important;

        div {
            width: 46px !important;
        }
    }

    &.marker-cluster-small div span,
    &.marker-cluster-medium div span,
    &.marker-cluster-large div span,
    &.marker-cluster-xlarge div span,
    &.marker-cluster-xxlarge div span {
        line-height: 30px !important;
        font-weight: $font-weight-bold !important;
    }

    &.marker-cluster-medium {
        margin-left: -28px !important;
        margin-top: -18px !important;
        width: 56px !important;
        height: 36px !important;
        border-radius: 18px !important;

        div {
            width: 50px !important;
        }
    }

    &.marker-cluster-large {
        margin-left: -30px !important;
        margin-top: -18px !important;
        width: 60px !important;
        height: 36px !important;
        border-radius: 18px !important;

        div {
            width: 54px !important;
        }
    }

    &.marker-cluster-xlarge {
        margin-left: -35px !important;
        margin-top: -18px !important;
        width: 70px !important;
        height: 36px !important;
        border-radius: 18px !important;

        div {
            width: 64px !important;
        }
    }

    &.marker-cluster-xxlarge {
        margin-left: -40px !important;
        margin-top: -18px !important;
        width: 80px !important;
        height: 36px !important;
        border-radius: 18px !important;

        div {
            width: 74px !important;
        }
    }
}

// animation for ant-path until I can figure out why style is stripped now
.leaflet-overlay-pane svg.leaflet-zoom-animated {
    -webkit-backface-visibility: hidden;
}

.leaflet-overlay-pane svg.leaflet-zoom-animated g path.leaflet-ant-path {
    animation-duration: 120s;
    -webkit-transform: translate3d(0, 0, 0);
}

.leaflet-overlay-pane svg.leaflet-zoom-animated g path.leaflet-ant-path[stroke-opacity="0.9"] {
    animation-duration: 60s;
}

ng-select.title {
    background: transparent;
    font-size: $h4-font-size !important;
    font-weight: $font-weight-semibold !important;
}

ng-select.title.subtitle {
    font-size: $h5-font-size !important;
}

.ng-select.title {
    text-align: left;
    flex-grow: 1;

    .ng-select-container {
        border-color: transparent;
        background: transparent !important;

        &:hover {
            border: 1px solid var(--gray-300);
            cursor: pointer;
        }

        .ng-value-container {
            text-align: left;
            padding-left: $spacer * 1.25;
        }
    }

    .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
        text-align: left;
        padding: math.div($spacer, 3) 0.75rem;
        padding-left: $spacer * 1.25;
    }

    .ng-arrow-wrapper {
        position: absolute;
    }
}

.ng-select.title.ng-select-opened > .ng-select-container {
    background: transparent;
    border-color: transparent;
}

.spinner-inline {
    .spinner-border {
        border-width: 0.125em;
    }
}

app-chart-container {
    svg {
        overflow: visible;
    }
    .axis-labels-text {
        font-family: $font-family-sans-serif;
        font-size: 12px;
    }
    .chart-tooltip {
        font-family: $font-family-sans-serif;
        font-weight: bold;
        font-size: 12px;
        z-index: 1;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5px;
        border-radius: 5px;
        background: black;
        color: white;
        .data {
            display: flex;
            width: 100%;
            gap: 15px;
            justify-content: space-between;
            .value {
                text-align: right;
            }
        }
    }
    .legend-item {
        display: flex;
        gap: 8px;
        align-items: center;
        font-weight: normal;
        font-size: 11px;
        .icon {
            width: 14px;
            height: 4px;
            border-radius: 1px;
        }
    }
    .legend-container {
        margin-top: 10px;
    }
}

/* Incidents */
app-incident-notes-dialog {
    h5 {
        margin-bottom: math.div($spacer, 4);
    }

    .modal-body {
        background-color: var(--body-bg);
    }
}

.incident-title {
    display: inline-block;
    max-width: 100%;

    h2 {
        display: block;
        float: left;
        margin: 0;
        max-width: 100%;
        margin-right: 0;
        margin-top: 0;

        &.subtitle {
            margin-left: math.div($spacer, 2);
            margin-top: 4px;
        }
    }

    ng-select.inline {
        display: block;
        float: left;
        width: auto;
        background: transparent;
        font-size: $h2-font-size !important;
        font-weight: $font-weight-semibold !important;

        ng-dropdown-panel {
            width: auto;
        }

        .ng-select-container {
            border-color: transparent;
            background: transparent;
            line-height: 1;
        }

        .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
            padding-left: 0.75rem;
            padding-right: 0.75rem;
        }
    }

    .ng-select.inline.ng-select-opened > .ng-select-container {
        border-color: transparent;
        background: transparent;
    }
}

.incident-title h2 .btn,
.broadcasters-title h2 .btn {
    top: -2px;
}

.separator {
    color: var(--gray-600);
}

/* Dark Mode */
.dark {
    .grid-page {
        .grid-object {
            &.isSelectable {
                &.selected {
                    background-color: var(--primary);
                    .grid-object-content {
                        border: 1px solid var(--primary);
                    }
                }
            }

            .grid-object-content {
                background-color: $dark-status-good-bg;
                color: $dark-text;

                &.error {
                    background-color: $dark-status-error-bg;
                    color: $dark-text;
                }

                &.warning {
                    background-color: $dark-status-warning-bg;
                    color: $dark-text;
                }

                &.none {
                    background-color: $dark-status-none-bg;
                    color: $dark-text;
                }
            }
        }

        .alert-light {
            color: $body-color;
            background-color: $blue-gray-300;
            border-color: $blue-gray-400;
        }
    }

    .grid-page .title-bar ::placeholder,
    .page-container .map-page ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $blue-gray-600 !important;
    }

    .grid-page .title-bar :-ms-input-placeholder,
    .page-container .map-page :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $blue-gray-600 !important;
    }

    .grid-page .title-bar ::-ms-input-placeholder,
    .page-container .map-page ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $blue-gray-600 !important;
    }

    .grid-page .grid-object .grid-object-content.grid-thumbnail {
        background-color: $blue-gray-900;
    }

    .grid-page .grid-thumbnail .thumbnail .aspect-ratio-fix .img-container img[src="assets/images/thumb-stream.png"] {
        opacity: 0.25;
    }

    .page-container {
        .map-page {
            .new-objects,
            .object-details {
                background-color: rgba(38, 50, 56, 0.9); // $blue-gray-900;
            }

            .leaflet-center {
                color: rgba(255, 255, 255, 0.5);
            }

            .leaflet-container {
                .leaflet-control-attribution {
                    background: rgba(38, 50, 56, 0.7); // $blue-gray-800;
                }
            }

            .leaflet-touch {
                .leaflet-control-layers,
                .leaflet-bar {
                    border-width: 1px !important;
                }

                .leaflet-control-layers {
                    background: none;
                }
            }

            .leaflet-control-layers-expanded {
                background: $blue-gray-900 !important;
            }

            .leaflet-control-layers-separator {
                border-top: 1px solid $dark-border;
            }

            .marker-cluster {
                .status-good {
                    color: $status-good;
                }
                .status-warning {
                    color: $status-warning;
                }
                .status-bad {
                    color: $status-error;
                }

                .status-disabled,
                .status-no,
                .status-none,
                .status-pending {
                    color: $status-none;
                }
            }

            .alert-danger {
                color: $dark-status-error;
            }

            .alert-warning {
                color: $dark-status-warning;
            }

            .alert-info {
                color: $dark-status-info;
            }

            .address-lookup .form-control,
            .marker-lookup .form-control {
                background: rgba(38, 50, 56, 0.9); // $blue-gray-900
            }

            .marker-results-list {
                background: rgba(38, 50, 56, 0.9); // $blue-gray-900
            }

            .leaflet-control-layers-base label div span::before {
                background-color: $dark-bg;
                border-color: $dark-border;
            }

            .leaflet-control-layers-overlays label div span::before {
                background-color: $dark-bg;
                border-color: $dark-border;
            }

            .leaflet-control-layers-overlays label div input[type="checkbox"]:checked ~ span::before {
                background-color: $dark-bg;
                border-color: $dark-border;
            }

            .leaflet-popup-content-wrapper,
            .leaflet-popup-tip {
                background: $blue-gray-800 !important;
                color: $white !important;
                box-shadow: $leaflet-box-shadow;
            }

            .leaflet-popup-content-wrapper .leaflet-popup-content ul li a span.item {
                color: $white;
            }
        }
    }
}

/* Iframe Loading Animation */
.iframe-load-animation {
    background-image: url(assets/images/Rolling-0.8s-41px.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

/* Content Loading Animation */
content-loading-animation {
    display: flex;
    flex: 1 1 auto;
    width: 100%;

    &.block {
        display: block;
        position: relative;
        min-height: 100px;

        .content-loading {
            position: absolute;
            left: 50%;
            top: 50%;
        }
    }
}

.content-loading {
    position: relative;
    flex: 1 1 auto;

    .spinner-border {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;
    }

    .dots-loader {
        top: 50%;
        left: 50%;
        margin-top: -4px;
        margin-left: -4px;
    }
}

/* Spinny Animation */
zx-events,
zx-scte,
app-broadcaster-active-objects,
app-source-downstream-objects,
app-network-objects-state,
zx-object-tracking-main-list,
app-target-traceroute,
zx-agentz-os-data,
zx-advanced-notes {
    spinner-animation {
        display: block;
        min-height: 100px;
        position: relative;

        .content-loading {
            position: absolute;
            left: 50%;
            top: 50%;
        }
    }
}

app-list-insights {
    width: 100%;
    zx-history-graph {
        display: block;
        border: 1px solid var(--gray-300);
    }
}

// Dark specific classes
.dark {
    color: var(--body-color);

    strong,
    h3,
    .nav-tabs .nav-item,
    app-source-webrtc-video .webrtc-video-container .webrtc-error-message {
        font-weight: $font-weight-semibold;
    }

    .nav-tabs {
        background: var(--color-disabled);
    }

    select {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%2364b5f6' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z' class=''%3e%3c/path%3e%3c/svg%3e") !important;
    }

    th[sortable].desc:after,
    th[sortable].asc:after {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%23eceff1' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z' class=''%3e%3c/path%3e%3c/svg%3e");
    }

    th[sortable].desc:after {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%23eceff1' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z' class=''%3e%3c/path%3e%3c/svg%3e");
    }

    .dropdown-item.desc:after,
    .dropdown-item.asc:after {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%23000000' d='M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z' class=''%3e%3c/path%3e%3c/svg%3e");
    }

    .dropdown-item.desc:after {
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512' %3e%3cpath fill='%23000000' d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z' class=''%3e%3c/path%3e%3c/svg%3e");
    }

    .details-panel .details-content-tabs .scroll-left-btn,
    .details-panel .details-content-tabs .scroll-right-btn {
        background-color: var(--color-disabled);
    }

    ::-webkit-scrollbar {
        width: $spacer;
        height: $spacer;
    }

    ::-webkit-scrollbar-track {
        background: $blue-gray-950;
    }

    ::-webkit-scrollbar-thumb {
        background: $blue-gray-700;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $blue-gray-600;
    }

    ::-webkit-scrollbar-corner {
        background: $blue-gray-800;
    }

    ::-webkit-scrollbar-button:single-button {
        display: block;
        background-color: $blue-gray-950;
        background-size: 8px;
        background-repeat: no-repeat;
    }
}

.darkmode-button {
    border-color: transparent !important;
    background-color: transparent !important;
    color: $white !important;

    &:hover,
    &:active {
        background-color: $white !important;
        color: $blue-gray-900 !important;
    }
}

/* Up */
::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 16px;
    width: 16px;
    background-position: center 8px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23455a64'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23546e7a'><polygon points='50,00 0,50 100,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23546e7a'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 16px;
    width: 16px;
    background-position: center 8px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23455a64'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23546e7a'><polygon points='0,0 100,0 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:vertical:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23546e7a'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: 16px;
    width: 16px;
    background-position: 5px 5px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23455a64'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23546e7a'><polygon points='0,50 50,100 50,0'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23546e7a'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: 16px;
    width: 16px;
    background-position: 5px 5px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23455a64'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23546e7a'><polygon points='0,0 0,100 50,50'/></svg>");
}

::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23546e7a'><polygon points='0,0 0,100 50,50'/></svg>");
}

//
/* Angular Material Table */
.tab-pane table.mat-mdc-table,
.tab-pane table.mat-table {
    position: relative;
}

table.mat-mdc-table {
    width: 100% !important;
    background: var(--white);
    border: 1px solid var(--gray-300);
    border-top: none;
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0;

    &.resizing {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: col-resize;
    }

    &.no-hover {
        .mat-mdc-row,
        .mat-row {
            &:hover:not(.selected) {
                background-color: transparent !important;
                cursor: default;
                .mat-mdc-cell,
                .mat-cell {
                    background-color: transparent !important;
                }
            }
        }
    }

    th {
        .mat-sort-header-content {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            align-items: flex-start;
            text-align: left;
        }

        &.mat-mdc-header-cell,
        &.mat-header-cell {
            border-color: var(--gray-300) !important;
            background-color: var(--gray-100) !important;
            color: var(--gray-800) !important;
            font-size: $h6-font-size !important;
            border-top-width: 1px;
        }

        &.mat-mdc-table-sticky {
            border-top: 1px solid var(--gray-300) !important;
            border-bottom: 1px solid var(--gray-300) !important;
            background: var(--gray-100) !important;
            // z-index: 100 !important;
        }
    }

    td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        // user-select: text;

        &.mat-mdc-cell,
        &.mat-cell {
            border-color: var(--gray-200) !important;
            color: var(--body-color);

            &.status-error {
                background-color: var(--active-bg-color-danger);
            }

            &.status-warning {
                background-color: var(--active-bg-color-warning);
            }

            &.status-good {
                background-color: var(--active-bg-color-success);
            }

            .unit {
                display: inline;
                font-size: $small-font-size;
                color: var(--gray-500);
                margin-left: math.div($spacer, 8);
            }

            app-source-thumbnail {
                display: block;
                height: 100%;
            }

            span.flag {
                display: block;
                line-height: 1;

                img {
                    height: math.div($spacer, 1.5);
                    float: left;
                    margin-right: math.div($spacer, 8);
                }
            }
        }

        // Sub table styling
        .expanded-row-content {
            overflow: auto;

            table.mat-mdc-table {
                border: none;

                tr.mat-mdc-header-row,
                tr.mat-header-row {
                    height: 28px !important;
                }

                th div.cdk-drag {
                    height: 28px;
                    line-height: 28px;
                }
            }
        }
    }

    td,
    th {
        &.checkbox-cell,
        &.expandbutton-cell {
            padding-right: 0 !important;
            padding-left: 0 !important;
            text-align: center !important;
            overflow: hidden !important;
            text-overflow: unset !important;
            width: 32px !important;
            min-width: 32px !important;
            max-width: 32px !important;
        }

        &.expandbutton-cell {
            cursor: pointer;
        }

        &.mat-column-thumbnails,
        &.mat-column-thumbnail {
            max-width: 110px;
        }
    }

    td {
        &.mat-column-thumbnails,
        &.mat-column-thumbnail {
            padding: 0 !important;
        }
    }

    tr {
        &.mat-mdc-header-row,
        &.mat-header-row {
            height: 35px !important;
        }

        &.mat-mdc-row,
        &.mat-row,
        &.mat-mdc-footer-row,
        &.mat-footer-row {
            height: 32px !important;
        }

        &.mat-mdc-row,
        &.mat-row {
            &.group-header-row {
                background-color: var(--gray-50);
                cursor: pointer;
            }

            &:not(.expanded-row) {
                &:hover {
                    background-color: var(--hover-bg-color) !important;
                    .mat-mdc-cell,
                    .mat-cell {
                        background-color: var(--hover-bg-color) !important;
                    }
                }

                &.mat-no-data-row,
                &.mat-mdc-no-data-row {
                    background-color: transparent;
                    cursor: default;
                    .mat-mdc-cell,
                    .mat-cell {
                        background-color: transparent;
                    }
                }

                &.selected {
                    .mat-mdc-cell,
                    .mat-cell {
                        background-color: var(--active-bg-color) !important;
                    }

                    &.show-deselect-icon {
                        td:last-child {
                            &::after {
                                position: absolute;
                                right: 1.5rem;
                                display: inline-block;
                                content: "\00d7"; /* This will render the 'X' */
                                background-color: var(--body-color);
                                color: var(--white);
                                width: $spacer;
                                height: $spacer;
                                border-radius: $spacer;
                                line-height: $spacer;
                                text-align: center;
                                margin-top: 2px;
                            }
                        }
                    }
                }
            }
        }
    }

    .mat-mdc-header-cell,
    .mat-header-cell {
        position: relative;
        &:not(:last-child) {
            .resize-holder {
                cursor: col-resize;
                width: 20px;
                height: 100%;
                position: absolute;
                right: -10px;
                top: 0;
                z-index: 1;
            }
        }
    }
    .mat-mdc-cell,
    .mat-mdc-header-cell,
    .mat-cell,
    .mat-header-cell {
        border-right: 1px solid var(--gray-200);
        &:not(.checkbox-cell) {
            padding: 0 math.div($spacer, 2);
        }
    }
    .mat-mdc-table-sticky-border-elem-left {
        box-shadow: math.div($spacer, 8) 0 math.div($spacer, 4) rgb(0 0 0 / 8%) !important;
        transform: scale(1, 1);
    }
}

.input-field {
    display: inherit !important;
}

.mat-sort-header-arrow,
.mat-sort-header-position-before .mat-sort-header-arrow {
    margin: 0 !important;
}

.checkbox-cell label {
    margin: 0;
}

.mat-sort-header-arrow {
    color: var(--gray-600) !important;
}

.mat-mdc-paginator-container {
    flex-direction: row-reverse !important;
    min-height: fit-content !important;
    padding: 0 !important;
}

.mat-mdc-paginator {
    background-color: transparent !important;
}

.mat-mdc-paginator,
.mat-mdc-paginator-page-size .mat-select-trigger {
    color: var(--gray-600) !important;
    font-size: $h6-font-size !important;
}

.mat-mdc-option {
    color: var(--body-color) !important;
    background: var(--white) !important;
    min-height: 32px !important;

    .mat-pseudo-checkbox-minimal {
        display: none;
    }

    &:hover {
        background: var(--hover-bg-color) !important;
    }
}

.mat-mdc-select-panel {
    padding: 0 !important;
    border: 1px solid var(--gray-300) !important;
    box-shadow: var(--box-shadow-sm) !important;
    font-size: $h6-font-size;
    min-width: 40px;
}

.mat-mdc-icon-button {
    --mdc-icon-button-disabled-icon-color: var(--gray-600) !important;
    --mdc-icon-button-disabled-icon-opacity: $btn-disabled-opacity !important;
    --mdc-icon-button-icon-color: var(--gray-600) !important;
    --mat-mdc-button-persistent-ripple-color: var(--black) !important;
}

.mat-mdc-icon-button[disabled] {
    opacity: $btn-disabled-opacity !important;
}

.mat-mdc-icon-button[disabled][disabled] {
    --mdc-icon-button-icon-color: var(--gray-600);
    --mdc-icon-button-disabled-icon-color: var(--gray-600);
}

.mat-mdc-button[disabled][disabled] {
    --mdc-text-button-disabled-label-text-color: var(--gray-600);
    --mdc-text-button-label-text-color: var(--gray-600);
}

.mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: var(--body-color);
}

.mat-mdc-button,
.mat-mdc-outlined-button {
    --mat-mdc-button-persistent-ripple-color: var(--black);
}

.mat-mdc-paginator-range-actions {
    position: relative !important;
    top: math.div($spacer, 2);
}

.mat-mdc-paginator-range-label {
    margin-left: $spacer !important;
    margin-right: $spacer !important;
    order: 10 !important;
}

.more-pages .mat-mdc-paginator-range-label::after {
    content: "+";
}

.mat-mdc-paginator-page-size {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled) .mat-mdc-text-field-wrapper {
    padding-left: math.div($spacer, 2);
    padding-right: math.div($spacer, 2);
}

.cdk-drag-preview {
    color: var(--body-color);

    &.mat-mdc-header-cell {
        border-color: var(--gray-300) !important;
        background-color: var(--gray-200) !important;
        color: var(--gray-800) !important;
        font-size: 0.7875rem !important;
        padding-left: math.div($spacer, 2);
        padding-right: math.div($spacer, 2);
        border: 1px solid var(--gray-300);
        line-height: 35px;

        .mat-sort-header-container {
            font-weight: $font-weight-semibold;
            font-size: $h6-font-size;
        }

        .mat-sort-header-arrow {
            display: none;
        }
    }
}

.mat-table-tab-container {
    display: flex;
    height: 100%;
    overflow: auto;
    padding: 0 $spacer;
    flex-direction: column;
}

.mat-table-accordion-container {
    display: flex;
    overflow: auto;
}

/* Angular Material Dialog */
.modal {
    --bs-modal-border-radius: 0.25rem;
    --bs-modal-inner-border-radius: 0.25rem;

    spinner-animation {
        display: block;
        height: 100px;

        .content-loading {
            position: static;
        }
    }
}

.mat-mdc-dialog-container {
    --mdc-dialog-container-elevation-shadow: none !important;
    padding: 0;
    background: var(--gray-50);
    max-height: 85vh !important;
    min-width: 280px !important;
    min-height: 300px !important;
    position: relative !important;
    display: flex !important;
    overflow: auto;
    border-radius: $border-radius;

    .mdc-dialog__container,
    .mdc-dialog__surface {
        display: flex !important;
        flex: 1 !important;
        overflow: auto !important;
    }

    .float-animation {
        position: absolute;
        left: 50%;
        top: 50%;
    }

    app-edit-rule-dialog {
        display: flex;
        flex: 1;
        overflow: auto;
        line-height: 1.5;
        background-color: var(--gray-50);

        form {
            display: flex;
            flex: 1;
            overflow: auto;
            flex-direction: column;

            .modal-header,
            .modal-footer {
                display: flex;
                flex: 0 0 auto;
            }

            .modal-content {
                display: flex;
                flex: 1;
                overflow: auto;
            }

            fieldset {
                .form-group {
                    margin-bottom: math.div($spacer, 2);
                }
            }
        }
    }
}

.mdc-checkbox {
    width: 16px !important;
    height: 16px !important;
    flex: 0 0 16px !important;
}

.mdc-checkbox__background {
    width: 16px !important;
    height: 16px !important;
}

.mdc-text-field--outlined {
    margin-top: 5px;
    --mdc-outlined-text-field-focus-outline-width: 1px !important;
    max-height: 34px !important;
}

.mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
        .mat-mdc-floating-label {
            top: 16px;
        }
    }
}

.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    top: 18px;
    font-size: 16px !important;
}

/* Angular Material Form Field */
mat-form-field {
    &.mat-form-field-appearance-outline {
        width: 100%;
        &.hasValue {
            .mdc-floating-label--float-above {
                color: var(--gray-700);
            }

            .mat-mdc-icon-button {
                --mdc-icon-button-icon-size: 14px !important;
            }
        }
    }

    .mat-mdc-form-field-infix {
        width: 100px;
    }
}

/* Angular Material Buttons */
.mat-button-toggle-group {
    --mat-legacy-button-toggle-height: 34px !important;
    --mat-legacy-button-toggle-shape: 4px !important;
    // so longer toggle groups don't force scrollbars on mobile screen sizes
    flex-wrap: wrap;
}

.mat-button-toggle-disabled {
    opacity: 0.5;
}

.mat-button-toggle-appearance-standard {
    .mat-button-toggle-label-content {
        line-height: 33px !important;
    }

    &.secondary {
        color: var(--secondary);
        &.mat-button-toggle-checked {
            background-color: var(--secondary);
        }

        &.mat-button-toggle-disabled {
            color: var(--secondary);

            &.mat-button-toggle-checked {
                background-color: var(--secondary);
            }
        }

        .mat-button-toggle-focus-overlay {
            background-color: var(--secondary);
        }
    }
}

.mat-button-toggle-group-appearance-standard {
    &.sm {
        --mat-legacy-button-toggle-height: 29px !important;

        .mat-button-toggle-label-content {
            line-height: 29px !important;
            padding: 0 math.div($spacer, 2);
        }
    }

    &.xs {
        --mat-legacy-button-toggle-height: 25px !important;

        .mat-button-toggle-label-content {
            line-height: 25px !important;
            padding: 0 math.div($spacer, 2);
        }
    }

    &.xxs {
        --mat-legacy-button-toggle-height: 21px !important;

        .mat-button-toggle-label-content {
            line-height: 21px !important;
            padding: 0 math.div($spacer, 2);
        }
    }
}

.more-option-btn {
    margin-left: 1.5em;
    display: inline-block;
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
}

/* Angular Material Tooltip */
.mat-mdc-tooltip {
    --mdc-plain-tooltip-supporting-text-tracking: 0 !important;
    --mdc-plain-tooltip-container-color: #000 !important;
    --mdc-plain-tooltip-supporting-text-color: #fff !important;
}

.MuiMenu-paper {
    background-color: var(--white) !important;
    border: 1px solid var(--gray-300) !important;
    box-shadow: var(--box-shadow-sm) !important;
    max-width: 400px !important;

    .MuiMenu-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .MuiMenuItem-root {
            border-bottom: 1px solid var(--gray-300);
            min-height: 30px;
            font-size: inherit;
            padding: 0 $spacer;

            &.text-primary {
                color: var(--primary) !important;
            }

            &.text-secondary {
                color: var(--secondary) !important;
            }

            &:hover {
                background: var(--hover-bg-color);
            }
        }
    }
}

/* Angular Material Menu */
.mat-mdc-menu-panel {
    background-color: var(--white);
    border: 1px solid var(--gray-300);
    box-shadow: var(--box-shadow-sm) !important;
    min-height: unset !important;
    max-width: 400px !important;

    .filter-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-wrap: wrap;
    }

    &.twoCol {
        .mat-mdc-menu-content {
            flex-direction: row;

            mat-checkbox {
                min-width: 50%;

                &:nth-child(even) {
                    border-left: 1px solid var(--gray-300);
                }
            }

            button {
                &.mat-mdc-menu-item {
                    flex: 1;
                    min-width: 50%;
                    &:nth-child(even) {
                        border-left: 1px solid var(--gray-300);
                    }

                    .mat-mdc-menu-item-text {
                        white-space: nowrap;
                        overflow-x: hidden;
                        text-overflow: ellipsis;
                        line-height: 1.5;
                    }
                }
            }
        }

        .filter-container {
            flex-direction: row;
            min-width: 396px;
        }
    }

    .mat-mdc-menu-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        .mat-mdc-menu-item {
            border-bottom: 1px solid var(--gray-300);
            min-height: 32px;
        }

        mat-checkbox {
            min-width: 100px;
            padding: math.div($spacer, 4) math.div($spacer, 1);
            width: auto;
            border-bottom: 1px solid var(--gray-300);
            flex: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 1.5;

            .mat-checkbox-layout {
                width: 100%;
                margin-bottom: 0;
                align-items: center;
                font-weight: 400;

                .mat-checkbox-inner-container {
                    width: 12px;
                    height: 12px;
                    margin: 0;
                    margin-right: 8px;
                }

                .mat-checkbox-label {
                    line-height: 22px;
                    color: var(--body-color);
                }
            }

            &:hover {
                background-color: var(--hover-bg-color);
            }
        }
    }
}

@include media-breakpoint-down(xs) {
    .mat-mdc-menu-panel {
        max-width: 300px !important;

        &.twoCol {
            .filter-container {
                min-width: 296px;
            }
        }
    }
}

.mat-mdc-checkbox {
    label {
        font-weight: $font-weight-normal !important;
        margin-bottom: 0 !important;
        color: var(--body-color);
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .mdc-form-field {
        line-height: 24px;
        color: var(--body-color);
        width: 100%;
    }
}

.mat-accent {
    --mat-option-selected-state-label-text-color: var(--body-color) !important;
}

.mat-mdc-card {
    --mat-card-title-text-line-height: 32px;
    --mat-card-title-text-size: 16px;
    --mat-card-title-text-tracking: 0;
    --mat-card-title-text-weight: 500;
}

//
app-table-list {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;

    app-table-material-wrapper {
        display: flex;
        flex: 1 1 auto;
        overflow: auto;
        max-width: 100%;
        min-width: 0;

        .list-section {
            display: flex;
            flex: 1 1 auto;
            overflow: auto;
            flex-direction: column;
            width: 100%;
            min-width: 0;
        }
    }
}

app-circle-status-indicator {
    margin-top: math.div($spacer, 4);
    line-height: 1.3;
    min-width: 100px;

    .inidicatr {
        height: $spacer * 1.1;
        width: $spacer * 1.1;
        border-radius: 50%;
        margin-right: math.div($spacer, 4);

        &.good {
            background-color: var(--status-good);
        }
        &.disable {
            background-color: var(--status-none);
        }
        &.warning {
            background-color: var(--status-warning);
        }
        &.bad {
            background-color: var(--status-error);
        }
    }
}

.tr101-tree-pid-removed,
.tr101-tree-pid-set-null {
    background-color: $status-error;
    pointer-events: none;
}

.tr101-tree-pid-was {
    background-color: $status-info;
    pointer-events: none;
}

// Gridstack
.widget-container {
    display: flex;
    flex: 1;
    overflow: auto;

    app-table-list {
        overflow-x: hidden;

        .table-filter-container {
            padding-left: $spacer;
            padding-right: $spacer;
        }
    }

    zx-broadcaster-details,
    app-cluster-details,
    app-source-details,
    app-source-stream,
    app-target-stream,
    app-mc-source-stream,
    app-channel-stream,
    app-all-channel-sources,
    app-channel-details,
    app-cluster-redirection-rules,
    zx-object-tracking-main-list {
        flex: 1;
        overflow: auto;
    }

    app-channel-sources {
        display: block;
        padding: math.div($spacer, 1.5);
        overflow: auto;

        .row {
            margin-left: -(math.div($spacer, 4));
            margin-right: -(math.div($spacer, 4));
            margin-bottom: math.div($spacer, 2);
            .col-sm-6 {
                margin-bottom: math.div($spacer, 2);
                padding-left: math.div($spacer, 4);
                padding-right: math.div($spacer, 4);
            }
        }
    }

    app-channel-ad-marks {
        overflow: auto;
    }

    zx-react-flow-diagram {
        overflow: auto;
    }

    app-object-incident-list,
    app-channel-targets {
        margin-top: math.div($spacer, 2);
    }

    zx-history-graph {
        flex: 1;
        .history-graph {
            padding-top: math.div($spacer, 2);

            .add-incident-button {
                margin-top: $spacer;
                margin-left: $spacer;
            }
        }
    }

    app-target-children,
    app-target-bonded-links {
        display: flex;
    }

    zx-events {
        display: flex;

        .table-filter-container {
            zx-events-types-filter {
                margin-left: 0 !important;
            }

            .optional-label {
                display: none !important;
            }
        }
    }

    app-broadcaster-active-objects {
        padding-top: math.div($spacer, 2);
        position: relative;
    }

    app-source-downstream-objects {
        padding-top: math.div($spacer, 4);
        position: relative;
    }

    zx-advanced-notes {
        flex: 1;
        padding: $spacer;
    }

    zx-agentz-os-data {
        flex: 1;
        position: relative;
    }

    app-broadcaster-config {
        flex: 1;
        padding-top: $spacer;
        padding-bottom: $spacer;
    }

    app-source-content-analysis,
    app-mc-source-content-analysis {
        flex: 1;
        padding: $spacer;
        overflow: auto;
    }

    app-source-bonding-info {
        flex: 1;
        padding: $spacer;
    }

    zx-scte {
        display: block;

        app-zx-table-actions {
            a {
                color: unset;
            }
        }
    }

    .details-form {
        padding: $spacer;
    }

    zx-grid {
        .grid-bottom {
            border-top: 1px solid var(--gray-300);

            .d-flex {
                padding: math.div($spacer, 2) $spacer;
                padding-bottom: 0;
                align-items: center;
                flex-wrap: wrap;

                > div {
                    margin-top: 0 !important;
                    margin-bottom: math.div($spacer, 2);
                }

                .pagination {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.gridstack-container {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;

    &.widgetResizing {
        iframe {
            pointer-events: none;
        }
    }

    &.isTab {
        padding: 0;

        .grid-stack {
            height: 100% !important;

            .grid-stack-item {
                height: 100%;
                flex: 1;

                .grid-stack-item-content {
                    display: block;
                    inset: 0;

                    .grid-item-container {
                        background-color: var(--white);
                        min-height: 100%;

                        .grid-item {
                            border: none !important;
                        }
                    }
                }
            }
        }
    }
}

.grid-stack-item {
    &.ui-draggable-disabled {
        .grid-item {
            border: 1px solid var(--gray-300) !important;
        }
    }

    > .ui-resizable-nw,
    > .ui-resizable-ne,
    > .ui-resizable-sw,
    > .ui-resizable-se {
        opacity: 0;
    }

    .grid-stack-item-content {
        background-color: var(--white);
        display: flex;
        overflow: unset !important;

        .grid-item-container {
            display: flex;
            flex: 1;
            overflow: auto;

            .grid-item {
                display: flex;
                flex: 1;
                flex-direction: column;
                border: 1px solid var(--gray-300);
                overflow: auto;

                &.expanded {
                    position: fixed;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    background-color: var(--white);
                    border: none !important;
                    z-index: 999;

                    h3 {
                        .expand-button {
                            right: 8px;
                        }
                    }
                }

                &:hover {
                    border: 1px solid var(--gray-400);
                }

                h3 {
                    position: relative;
                    padding: 0.5rem 1rem;
                    border-bottom: 1px solid var(--gray-300);
                    margin: 0;
                    flex: 0 1 auto;
                }
            }
        }
    }
}

.accordion-body {
    zx-react-flow-diagram {
        min-height: 400px;
    }
}

zx-react-flow-diagram {
    display: flex;
    flex: 1;

    .react-diagram {
        display: flex;
        flex: 1;
    }

    .react-flow__node {
        background-color: transparent;
        border-radius: 4px;
        font-size: $h5-font-size;

        .label {
            font-size: $h5-font-size;
        }

        .handles {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            position: absolute;

            .react-flow__handle {
                position: relative;
                transform: none;
                border: transparent;
                background: transparent;
            }

            &.sources {
                &.DOWN {
                    width: 100%;
                    height: 5px;
                    right: 0;
                    bottom: 0;

                    .react-flow__handle {
                        left: auto;
                    }
                }

                &.RIGHT {
                    height: 100%;
                    width: 5px;
                    right: 0;
                    top: 0;
                    flex-direction: column;

                    .react-flow__handle {
                        top: auto;
                    }
                }
            }

            &.targets {
                &.DOWN {
                    width: 100%;
                    height: 5px;
                    right: 0;
                    top: 0;

                    .react-flow__handle {
                        left: auto;
                    }
                }

                &.RIGHT {
                    height: 100%;
                    width: 5px;
                    left: 0;
                    top: 0;
                    flex-direction: column;

                    .react-flow__handle {
                        top: auto;
                    }
                }
            }
        }

        &.parent {
            background-color: var(--white);
            color: var(--body-color);
            border-width: 2px !important;
            border-style: dashed;
            border-color: var(--gray-400);

            .target,
            .source {
                opacity: 0;
                pointer-events: none;
            }

            &.selectable {
                &:hover {
                    box-shadow: none;
                    border-color: var(--gray-500);
                    border-style: solid;
                }
            }

            &.selected {
                border-color: var(--gray-600) !important;
                border-style: solid;
                box-shadow: none;
            }
        }

        .nodeBackground {
            background-color: var(--gray-50);
            border: 1px solid var(--status-none);
            border-radius: $border-radius;
            font-size: $h5-font-size;
            font-family: $font-family-sans-serif;
            display: flex;
            flex-direction: column;
            opacity: 1 !important;
            color: var(--body-color);

            &.good {
                background-color: var(--green-50);
                border: 1px solid var(--status-good);
                .nodeStatus {
                    background-color: var(--status-good);
                }
            }

            &.error {
                background-color: var(--red-50);
                border: 1px solid var(--status-error);
                .nodeStatus {
                    background-color: var(--status-error);
                }
            }

            &.warning {
                background-color: var(--orange-50);
                border: 1px solid var(--status-warning);
                .nodeStatus {
                    background-color: var(--status-warning);
                }
            }

            .nodeHeader {
                display: flex;
                flex: 1 0 auto;

                .nodeIcon {
                    flex: 0 0 auto;
                    padding: math.div($spacer, 4) math.div($spacer, 4) math.div($spacer, 4) math.div($spacer, 2);
                }

                .nodeType {
                    padding: math.div($spacer, 4) math.div($spacer, 2);
                    padding-left: 0;
                    flex: 1 1 auto;
                    font-weight: $font-weight-semibold;
                }
            }

            .nodeStatus {
                display: flex;
                flex: 1 1 auto;
                color: var(--color-white);
                background-color: var(--status-none);
                font-size: 14px;
                pointer-events: all;

                .nodeName {
                    display: flex;
                    flex: 1 1 auto;
                    padding: math.div($spacer, 4) math.div($spacer, 1.5);
                    padding-right: 0;
                    font-size: 16px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    div {
                        display: flex;
                        align-items: center;
                        flex: 1 1 auto;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;

                        i {
                            margin-right: 3px;
                            color: var(--color-white) !important;
                        }

                        a {
                            color: var(--color-white);
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .nodeActions {
                    display: flex;
                    flex: 0 0 auto;
                    padding: 0;

                    .MuiButton-root {
                        min-width: 30px;
                        color: var(--color-white);
                        padding-left: math.div($spacer, 1.5);
                        padding-right: math.div($spacer, 1.5);
                    }
                }
            }

            .nodeThumbnail {
                position: relative;
                pointer-events: all;

                .thumbnail {
                    border: none;
                    border-radius: 0;
                    padding: 0;

                    .img-container {
                        border: none;

                        .img-loading {
                            position: relative;
                            height: 100%;
                            width: 100%;

                            .spinner-border {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                margin-top: -16px;
                                margin-left: -16px;
                            }
                        }
                    }
                }
            }

            .nodeFields {
                display: flex;
                flex: 1 1 auto;
                flex-wrap: wrap;

                .nodeField {
                    flex: 1 1 100%;
                    padding: math.div($spacer, 8) math.div($spacer, 1.5);
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    overflow: hidden;

                    &.isError {
                        justify-content: flex-start;

                        .value {
                            text-align: left;

                            span {
                                border-bottom: 1px dotted currentColor;
                            }
                        }
                    }

                    .key {
                        flex: 0 0 auto;
                        margin-right: math.div($spacer, 4);
                    }

                    .value {
                        flex: 1 1 auto;
                        text-align: right;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        pointer-events: all;

                        &.hasTooltip {
                            span {
                                border-bottom: 1px dotted currentColor;
                            }
                        }

                        .unit {
                            font-size: $h6-font-size;
                            margin-left: 2px;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
    }

    .react-flow__edge-textbg {
        fill: var(--gray-700);
        stroke-width: 3px;
        stroke: var(--gray-700);
    }

    .edgeLabel {
        z-index: 1001;
        background-color: var(--gray-700);
        padding: math.div($spacer, 8) math.div($spacer, 4);
        color: var(--white);
        border-radius: $border-radius;
        font-weight: $font-weight-bold;
    }

    .react-flow__edge-text {
        font-size: $h5-font-size;
        font-weight: $font-weight-bold;
        fill: var(--white);
    }

    .react-flow__edge-path,
    .react-flow__connection-path {
        stroke: var(--gray-700) !important;
        stroke-width: 3;

        &.downstream {
            stroke: var(--gray-400) !important;
        }
    }

    .react-flow__arrowhead {
        polyline {
            stroke: var(--gray-700) !important;
            fill: var(--gray-700) !important;
        }

        &[id="1__className=downstream&color=#757575&height=12&type=arrow&width=12"] {
            polyline {
                stroke: var(--gray-400) !important;
                fill: none !important;
            }
        }
    }
    .react-flow__resize-control {
        &.line {
            border-color: var(--gray-600);
        }

        &.handle {
            width: 12px;
            height: 12px;
            border: none;
            border-radius: 0;
            background-color: var(--black);
        }
    }

    .react-flow__minimap {
        background-color: var(--white);
        svg {
            width: 140px;
            height: 100px;
            box-shadow: var(--box-shadow-sm);
            cursor: grab;

            .react-flow__minimap-mask {
                opacity: 0.8;
                fill: var(--color-disabled);
            }

            .react-flow__minimap-node {
                fill: var(--status-none);

                &.group {
                    stroke: var(--gray-400);
                    stroke-width: 8px;
                    fill: transparent;
                }

                &.warning {
                    fill: var(--status-warning);
                }

                &.good {
                    fill: var(--status-good);
                }

                &.error {
                    fill: var(--status-error);
                }
            }
        }
    }

    .react-flow__attribution {
        display: none;
        background: transparent;
        color: var(--gray-600);
    }

    .react-flow__controls {
        box-shadow: var(--box-shadow-sm);
        border-top: 1px solid var(--gray-300);
        margin: $spacer;

        .react-flow__controls-button {
            background: var(--gray-100);
            border: 1px solid var(--gray-300);
            border-top: none;
            border-color: var(--gray-300);
            fill: var(--body-color);

            &:hover {
                background-color: var(--gray-200);
            }
        }

        .react-flow__controls-interactive {
            width: 0;
            height: 0;
            opacity: 0;
            padding: 0;
            border: none;
        }
    }

    .react-flow__panel {
        .MuiButton-outlinedSecondary {
            background-color: var(--gray-100) !important;
            border: 1px solid var(--gray-300) !important;
            box-shadow: var(--box-shadow-sm);
            color: var(--body-color);

            &:hover {
                background-color: var(--gray-200) !important;
            }
        }

        .MuiButton-sizeSmall {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 27px;
            height: 27px;
            min-width: unset;
            padding: 5px;
            border-radius: 0;
        }
    }
}

.disable-show-tooltip:disabled {
    pointer-events: auto;
    cursor: default;
}

.grecaptcha-badge {
    visibility: hidden;
}

/* Print */
@media print {
    app-critical-announcements {
        display: none;
    }

    html {
        position: relative !important;
        display: block !important;
        overflow: visible !important;
        height: 100% !important;
        width: 100% !important;
    }

    body {
        width: 100% !important;
        height: 100% !important;
    }

    body,
    .flex-container,
    .content-container,
    content,
    .outlet-container,
    .panel-container,
    app-incident,
    .incident-container,
    .right-container,
    .new-right-container,
    .details-panel,
    .details-content-accordions {
        display: block !important;
        height: auto !important;
        overflow: visible !important;
        color: #000 !important;
    }

    .details-content-container {
        clear: both;
    }

    .badge {
        color: #000 !important;
    }

    .btn {
        background: transparent !important;
        color: #000 !important;
    }

    label,
    legend {
        color: #000 !important;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #000 !important;
    }

    .form-control {
        color: #000 !important;
    }

    .table {
        color: #000 !important;
    }
}
